import { Field, FieldAttributes, useField } from 'formik';
import { nanoid } from 'nanoid';
import React, { useRef } from 'react';

interface Props extends FieldAttributes<any> {
  name: string;
  children?: React.ReactNode;
}

export default function FormikField({
  name,
  children,
  ...props
}: Readonly<Props>): JSX.Element {
  const [, meta] = useField(name);

  // Avoid ID collisions by prefixing with a unique string
  const id = useRef(`${nanoid()}-${name}`).current;

  return (
    <Field
      id={id}
      name={name}
      error={meta.touched && meta.error}
      errorMessage={meta.error}
      {...props}
    >
      {children}
    </Field>
  );
}
