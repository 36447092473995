import { LinkText } from '@postscript/components';
import { useGetUserProfile } from 'components/account/AccountView/users/hooks/useUsers';
import useAccountStatus from 'components/account/useAccountStatus';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import {
  HELP_ARTICLES,
  SUPPORTED_DSC_COUNTRY_CODES,
} from 'constants/constants';
import { useUser } from 'controllers/contexts/user';
import { useEffect } from 'react';
import { PhoneNumberData } from 'types';
import { BANNER_TYPES } from 'utils/events';
import TNFVerificationSubmissionModal from './TNFVerificationSubmissionModal';

function hasValidNumber(phoneNumbers: PhoneNumberData): boolean {
  // Shops with unverified TFNs can send messages via a short code or with an international long code (excluding Canada)

  const hasShortCode = SUPPORTED_DSC_COUNTRY_CODES.some((countryCode) => {
    const key = `${countryCode.toLocaleLowerCase()}_short_code` as const;
    const phoneNumber = phoneNumbers[key] ?? '';

    // Matches messaging logic for short code validation in the backend
    return phoneNumber.length > 3 && phoneNumber.length < 7;
  });

  // Get any non-CA international long code
  const hasIntlLongCode = !!Array.from(Object.keys(phoneNumbers)).find(
    (phoneNumberKey) => {
      return !['us', 'ca'].includes(phoneNumberKey.substring(0, 2));
    },
  );

  return hasShortCode || hasIntlLongCode;
}

const tfnVerificationBannerId = 'tfnVerificationBanner';

const buildVerificationMailto = (
  shopName: string,
  firstName = shopName,
): string =>
  `mailto:verifications@postscript.io?subject=TFN%20verification%20status%20update%20request%20from%20${shopName}&body=Hello%20Postscript%2C%0D%0A%0D%0AWhat%E2%80%99s%20the%20toll-free%20number%20(TFN)%20verification%20status%20for%20${shopName}%3F%0D%0A%0D%0AThanks!%20%0D%0A${firstName}`;

export default function TFNVerificationStatusBanner(): null {
  const { addBanner, removeBanner } = useBanners();
  const { showModal } = useGlobalModal();
  const { tfnVerificationStatus, isFetched } = useAccountStatus();
  const status = tfnVerificationStatus ?? '';
  const {
    user: { shop_name: shopName, custom_phone_number_data: phoneNumbers },
  } = useUser();
  const { data: profile } = useGetUserProfile();

  useEffect(() => {
    removeBanner(tfnVerificationBannerId);

    if (
      !phoneNumbers ||
      !isFetched ||
      hasValidNumber(phoneNumbers) ||
      !shopName
    ) {
      return;
    }

    if (status === 'INVALID') {
      return addBanner({
        id: tfnVerificationBannerId,
        eventTrackingOptions: {
          bannerType: BANNER_TYPES.TFN_VERIFICATION_STATUS,
        },
        data: {
          collapseAt: 1200,
          variant: 'critical',
          heading:
            '[Action required] Your toll-free number (TFN) was not submitted for verification',
          bodyText: (
            <>
              Before you can start collecting subscribers or sending messages,
              US mobile carriers require verification of your business in order
              to activate your TFN. There was a problem submitting your TFN for
              verification due to invalid or missing information.
            </>
          ),
          primaryAction: {
            text: 'Retry Submission',
            onClick: () => {
              showModal({
                children: <TNFVerificationSubmissionModal />,
                dismissOnBackdropClick: true,
              });
            },
          },
          secondaryAction: {
            text: 'Learn More',
            onClick: () => window.open(HELP_ARTICLES.tfnVerification, '_blank'),
          },
        },
      });
    }

    if (['CREATED', 'VALID', 'SUBMITTED', 'REVIEWED'].includes(status)) {
      return addBanner({
        id: tfnVerificationBannerId,
        eventTrackingOptions: {
          bannerType: BANNER_TYPES.TFN_VERIFICATION_STATUS,
        },
        data: {
          collapseAt: 1200,
          variant: 'default',
          heading: 'Your toll-free number (TFN) was submitted for verification',
          bodyText: (
            <>
              Before you can start collecting subscribers or sending messages,
              US mobile carriers require verification of your business in order
              to activate your TFN. This process can take up to five business
              days.{' '}
              <LinkText
                style={{ color: 'var(--text-color)' }}
                href={HELP_ARTICLES.tfnVerification}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </LinkText>
            </>
          ),
        },
      });
    }

    if (['FLAGGED', 'FAILED', 'DENIED'].includes(status)) {
      return addBanner({
        id: tfnVerificationBannerId,
        eventTrackingOptions: {
          bannerType: BANNER_TYPES.TFN_VERIFICATION_STATUS,
        },
        data: {
          collapseAt: 1200,
          variant: 'critical',
          heading: '[Action required] Verify your toll-free number (TFN)',
          bodyText: (
            <>
              Your TFN is <strong>not verified</strong> and will not be able to
              send messages nor gather subscribers. Request a verification
              status update to ensure messages sent from your number aren&apos;t
              blocked.
            </>
          ),
          primaryAction: {
            text: 'Request Verification Status',
            onClick: () =>
              window.open(
                buildVerificationMailto(
                  shopName,
                  profile?.firstName ?? undefined,
                ),
                '_blank',
              ),
          },
          secondaryAction: {
            text: 'Learn More',
            onClick: () => window.open(HELP_ARTICLES.tfnVerification, '_blank'),
          },
        },
      });
    }
  }, [phoneNumbers, status, isFetched, shopName]);

  return null;
}
