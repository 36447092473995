import { QueryCacheTimes } from 'components/flowBuilder/constants';
import { api } from 'controllers/network/apiClient';
import { useQuery, UseQueryResult } from 'react-query';
import { ShopData } from 'types';

const isShopData = (data: unknown): data is ShopData => {
  const dataValue = data as ShopData;

  if (!dataValue || typeof dataValue !== 'object') return false;
  if (typeof dataValue.campaigns_approved !== 'boolean') return false;
  if (
    typeof dataValue.l30_shopify_orders !== 'number' &&
    dataValue.l30_shopify_orders !== null
  )
    return false;
  if (typeof dataValue.shop_id !== 'number') return false;

  return true;
};

const fetchShopData = async (): Promise<ShopData> => {
  const shopData: unknown = await api.get('/v2/shops/gtm-data/');
  if (!isShopData(shopData)) throw new Error('Unexpected shop data response');
  return shopData;
};

const useShopData = (): UseQueryResult<ShopData> => {
  return useQuery(['shop-data'], () => fetchShopData(), {
    staleTime: QueryCacheTimes.MEDIUM,
  });
};

export const withShopData = <P extends Record<string, any>>(
  Component: React.ComponentType<P>,
): React.ComponentType<P> => {
  return (props) => {
    return <Component {...props} shopData={useShopData()} />;
  };
};

export default useShopData;
