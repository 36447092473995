import { Layout } from '@postscript/components';
import React from 'react';
import styled from 'styled-components';

const StyledLayout = styled(Layout).attrs({
  vertical: true,
})`
  width: 100%;

  & > div,
  // Supports nested Layout component for side-by-side fields
  & > div > div:has(input) {
    width: 100%;
  }

  button[type='submit']:not(.modal__footer button[type='submit']) {
    margin-top: var(--spacing-2);
  }

  & > div > div:not(:has(> input)) {
    & > div {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    & > div:has(input) {
      flex-direction: column;
    }

    & > div + div > div:not(:first-child):not(:has(> input)) {
      margin-top: var(--spacing-2) !important;
    }
  }
`;

interface Props extends React.ComponentProps<typeof Layout> {
  children: React.ReactNode;
  vertical?: true; // Layout doesn't want a boolean for vertical for some reason; this should be fixed
}

export default function FormLayout({
  children,
  ...props
}: Readonly<Props>): JSX.Element {
  return <StyledLayout {...props}>{children}</StyledLayout>;
}
