/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable-next-line react/no-array-index-key */
import {
  Badge,
  Banner,
  BodyText,
  Button,
  ButtonAnchor,
  Card,
  DataCard,
  EmptyState,
  Heading,
  Highlight,
  Icon,
  IconSet,
  Layout,
  Pagination,
  Switch,
  TabGroup,
  TabPanel,
  toast,
  Tooltip,
} from '@postscript/components';
import { getShopId } from 'components/account/AccountView/users/helpers';
import PageHeader from 'components/layout/PageHeader';
import useSubscriber from 'components/responses/hooks/useSubscriber';
import ConversationCardNoSummaryBody from 'components/sales/components/ConversationCardNoSummaryBody';
import MessageThread from 'components/sales/components/MessageThread';
import SentimentBar from 'components/sales/components/SentimentBar';
import { useSalesShopData } from 'components/sales/hooks/useShopData';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useUser } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import LogRocket from 'logrocket';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import MediaQueries from 'utils/mediaQueries';
import ConversationIntelligenceControls, {
  Controls,
  FILTER_LABEL_LOOKUP,
  HAS_SUMMARY,
  MOST_RECENT_INBOUND,
} from '../components/ConversationIntelligenceControls';
import { ViewSelector } from '../components/ViewSelector';
import { VIEW_OPTIONS } from '../constants';
import {
  ConversationIntelligenceUser,
  ConversationsWithInsightsResponse,
  ConversationWithInsights,
  InsightsPayload,
} from '../hooks/useConversationSummaries';
import { useView } from '../hooks/useView';
import { TopicLabelTrendGroup, TopicLabelTrends } from '../types';

const MAX_TOPIC_LABEL_RANK = 1;
const TOPIC_LABEL_VERSION = '0';

const isBubAI = () => {
  return (
    localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY) ===
    'Bub AI Debugger View'
  );
};

const getBreakpoint = () => {
  return isBubAI() ? '1280px' : '767px';
};

const TOPICS_TAB_PANEL_ID = 'topics-tab-panel';
const TAGS_TAB_PANEL_ID = 'tags-tab-panel';

const MAX_TAGS_TO_DISPLAY = 50;

const StyledBetaBadge = styled(Badge)`
  vertical-align: super;
  margin-left: var(--spacing-1);
  letter-spacing: normal;
`;

const StyledCIColumns = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
  position: relative;
  max-width: 100%;
`;

const StyledCardButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-1);

  .view-conversation-button {
    min-width: 148px;
  }
  .subscriber-details-button {
    min-width: 138px;
  }
`;

const StyledCardEmptyState = styled(EmptyState)`
  padding: var(--spacing-4) 0;

  .no-insights & {
    flex: 1 0;
  }
`;

interface StyledHighlightProps {
  highlighted?: boolean;
}

const StyledHashtagLineItem = styled.div<StyledHighlightProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3px 6px 3px 3px;
  border-radius: var(--border-radius-x-small);
  gap: var(--spacing-2);
  width: 100%;
  cursor: default;
  transition: background var(--hover-transition);

  ${({ highlighted }) =>
    highlighted
      ? css`
          background: var(--color-topic-primary);
        `
      : css`
          &:hover {
            background: var(--border-color-extra-dim);
          }
        `}
`;

const StyledChart = styled.div`
  .single-col & {
    max-width: 1236px;
  }
`;

const StyledHashtagCard = styled(Card)`
  border-radius: var(--border-radius-medium);
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  padding: var(--spacing-3);
  margin-bottom: var(--spacing-2);
  width: 100%;
  position: sticky;
  top: var(--spacing-2);
`;

const StyledHashtagHeader = styled.div`
  display: flex;
  gap: var(--spacing-1);
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: var(--spacing-1);
`;

const StyledHashtagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  align-self: stretch;
`;

const MainColumn = styled(Layout)`
  flex: 1 0;
  gap: var(--spacing-6);

  > * {
    width: 100%;
  }

  .single-col & {
    max-width: 100%;
  }

  .multi-col & {
    max-width: calc(100% - 360px);

    @media only screen and (max-width: ${getBreakpoint()}) {
      max-width: calc(100% - 288px);
    }
  }
`;

const TitleAndChart = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
`;

const SummaryCardsAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;

const StyledPaginationWrapper = styled.div`
  padding-top: var(--spacing-4);

  .single-col & {
    max-width: 1236px;
  }
`;

const DataCardLayout = styled.div`
  display: flex;
  gap: var(--spacing-2);

  > * {
    flex: 1 0;
    max-width: 288px !important;
    position: relative;

    &.tall {
      padding-bottom: var(--spacing-10);
    }
  }

  .multi-col & {
    @media only screen and (max-width: 876px) {
      flex-direction: column;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1164px) {
      flex-direction: column;
    }
  }

  .single-col & {
    max-width: 1236px;
  }
`;

const StyledJsonButton = styled(Button)`
  position: absolute;
  left: var(--spacing-4);
  top: 84px;

  &.drop {
    top: 108px;
  }
`;

const StyledSkeletonCard = styled.div`
  flex: 1 0 auto;
  border-radius: var(--border-radius-medium);
  height: 120px;
  padding: var(--spacing-4) var(--spacing-5);
  transition: background 250ms;

  background: var(--color-skeleton-loader);
  animation: skeletonLoaderAnimation 1000ms infinite;

  .single-col & {
    max-width: 1236px;
  }
`;

const StyledSkeletonCardTall = styled(StyledSkeletonCard)`
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;

  .single-col & {
    max-width: 1236px;
  }
`;

const StyledTabGroup = styled(TabGroup)`
  display: flex;
  padding-bottom: var(--spacing-2);
  padding-left: var(--spacing-1);
`;

const ViewMoreTagsWrapper = styled.div`
  margin-top: var(--spacing-2);
  position: relative;
  text-align: center;
  width: 100%;
`;

const ViewMoreHr = styled.div`
  background: var(--border-color-dim);
  display: block;
  height: 1px;
  left: 0;
  margin: unset;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 0;
`;

const ViewMoreButtonWrapper = styled.span`
  position: relative;
  display: inline-block;
  background-color: var(--white);
  padding: var(--spacing-1);
  z-index: 1;
`;

const StyledTopicCategoryGroup = styled.div`
  align-self: stretch;

  &:not(:last-child) {
    padding-bottom: var(--spacing-2);
  }
`;

const TopicCategoryTitle = styled.div`
  display: flex;
  gap: var(--spacing-1);
  padding: 0px var(--spacing-1) var(--spacing-1);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const StyledSkeletonHashtagCard = styled(StyledSkeletonCard)`
  flex: 1 0 auto;
  height: 312px;
  border-radius: var(--border-radius-medium);
  width: 100%;
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  transition: background 250ms;

  background: var(--color-skeleton-loader);
  animation: skeletonLoaderAnimation 1000ms infinite;
`;

const StyledVoidCard = styled.div`
  background: var(--surface-bkg-color);
  height: 280px;
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-small);
  display: flex;
  align-items: center;
  justify-content: center;

  .single-col & {
    max-width: 1236px;
  }
`;

const RightColumn = styled.div`
  max-width: 420px;
  min-width: 360px;
  flex: 1 1 420px;
  border-radius: var(--border-radius-medium);
  align-self: stretch;

  @media only screen and (max-width: ${getBreakpoint()}) {
    max-width: 288px;
    min-width: 288px;
    flex: 1 1 288px;
  }
`;

export const ChatSubContainer = styled.div`
  position: relative;
  overflow: auto;
  padding: 0 var(--spacing-4);

  ul {
    li:last-child {
      padding-bottom: var(--spacing-4);
    }
  }
`;

const StyledChatSubContainer = styled(ChatSubContainer)`
  padding: 0;
  border-top: 1px solid var(--border-color-dim);
  border-bottom: 1px solid var(--border-color-dim);
`;

const StyledSummaryCard = styled.article`
  border: 1px solid transparent;
  position: relative;
  border-radius: var(--border-radius-medium);
  border: 1px solid transparent;
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  transition: border-color var(--hover-transition);
  padding: var(--spacing-4) var(--spacing-5);

  > div {
    display: flex;
    gap: var(--spacing-4);
    width: 100%;
  }

  &:not(.no-insights) > div {
    align-items: flex-start;
  }

  &.active {
    border: 1px solid var(--color-topic-primary);
    box-shadow: 0px 0px 5px 0px var(--color-topic-primary);
  }

  @media only screen and (max-width: ${getBreakpoint()}) {
    > div {
      flex-direction: column;
      gap: var(--spacing-3);
    }
  }

  .single-col & {
    max-width: 1236px;
  }
`;

const StyledTitleContainer = styled.div`
  background: var(--main-bkg-color);
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: var(--text-color-dim);
  position: relative;

  .single-col & {
    max-width: 1236px;
  }
`;

const StyledSearchHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  align-self: stretch;
`;

const StyledSearchHeaderLogline = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  padding: 0 96px 0 var(--spacing-5);
  transition: opacity var(--hover-transition);
`;

const StyledSearchText = styled(Heading)`
  flex: 1 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & mark {
    color: var(--color-topic-primary);
  }
`;

const StyledSearchButtons = styled.div`
  display: flex;
  gap: var(--spacing-1);
  pointer-events: none;
`;

const StyledSummaryHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  width: 100%;
`;

const StyledSearchHeader = styled.div<{ filtersSelected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: var(--spacing-2);
  min-height: 69px;
  cursor: pointer;

  background: transparent;
  border-radius: var(--border-radius-medium);
  transition: background var(--hover-transition),
    box-shadow var(--hover-transition);

  ${({ filtersSelected }) =>
    filtersSelected
      ? css`
          & ${StyledSearchText} {
            color: var(--text-color);
          }
        `
      : css`
          &:not(:hover) ${StyledSearchHeaderLogline} {
            opacity: 0;
          }
        `};

  &:hover {
    background: var(--surface-bkg-color);
    box-shadow: 0 0 0 1px var(--border-color-extra-dim) inset,
      var(--box-shadow-small);
  }
`;

const StyledLogLine = styled.div`
  display: flex;
  gap: var(--spacing-1);
  flex-wrap: wrap;
`;

const StyledLogLineItem = styled.div`
  display: inline-flex;
  gap: 3px;
`;

const ChatCard = styled.article`
  background: var(--modal-bkg-color);
  box-shadow: var(--box-shadow-large);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: var(--spacing-4) var(--spacing-4) 72px var(--spacing-6); // 72 to account for chat button
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  max-width: 480px;

  transform: translateX(480px);
  transition: transform 500ms;
  will-change: transform;
`;

const ChatBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 250ms;
  will-change: opacity;
`;

const ChatContainer = styled.div<{ isOpen?: boolean }>`
  overflow: hidden;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: z-index 1ms;

  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
          pointer-events: all;
          height: 100vh;
          width: 100%;
          z-index: 1000;

          ${ChatCard} {
            transform: translateX(0);
          }
          ${ChatBackdrop} {
            opacity: 1;
          }
        `
      : css`
          transition-delay: 500ms;
          pointer-events: none;
        `};
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-2);
  padding-bottom: var(--spacing-3);
`;

const ChatSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2);
`;

const ChatActions = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-1);
  padding: var(--spacing-2) 0 0;
`;

const ChatEmpty = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--spacing-4);
  align-items: center;
  justify-content: center;
`;

const StyledSumaryCardLeftCol = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  flex: 1 1 auto;
  gap: var(--spacing-3);
  max-width: 720px;
`;

const StyledSumaryCardRightCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);

  flex: 1 1;
  min-width: 204px;
  padding-left: var(--spacing-2);
  border-left: 1px solid var(--border-color-dim);

  @media only screen and (max-width: ${getBreakpoint()}) {
    flex-direction: column;
    flex: 1 0 100%;
    width: 100%;
    padding-left: 0;
    padding-top: var(--spacing-3);
    border-top: 1px solid var(--border-color-dim);
    border-left: none;
  }
`;

const StyledCardTabGroup = styled(TabGroup)`
  margin-top: -12px;
`;

const StyledCardTabPanel = styled(TabPanel)`
  margin-top: -6px;
`;

const StyledDebugContents = styled.div`
  padding: var(--spacing-1) var(--spacing-2);
  border: 1px solid var(--border-color-extra-dim);
  border-radius: var(--border-radius-small);
`;

const StyledHashtags = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);

  .no-insights & {
    flex: 1 0;
  }
`;

const StyledCardSidebarItem = styled.div`
  display: flex;
  gap: var(--spacing-1);
  color: var(--text-color-dim);
  width: 100%;
  font: var(--body-text-x-small);
`;

const StyledTopic = styled.a<StyledHighlightProps>`
  display: flex;
  gap: var(--spacing-1);
  color: var(--text-color-dim);
  width: 100%;
  font: var(--body-text-x-small);
  cursor: pointer;

  ${({ highlighted = false }) => {
    if (highlighted) {
      return css`
        color: var(--white);

        &:hover {
          color: var(--white);
          text-decoration: underline;
        }

        &:active {
          color: var(--gray-3);
          text-decoration: underline;
        }
      `;
    }
    return css`
      color: var(--text-color-dim);

      &:hover {
        color: var(--link-color);
        text-decoration: underline;
      }

      &:active {
        color: var(--link-color-hover);
        text-decoration: underline;
      }
    `;
  }}

  &.bold {
    font-weight: 650;
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 0;
    text-decoration: underline;
  }
`;

const StyledCardHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-4);
  padding: 0 var(--spacing-2) var(--spacing-2);

  .single-col & {
    max-width: 1236px;
  }
`;

const StyledCardHeading = styled(Heading).attrs({
  size: 'x-small',
})`
  color: var(--text-color-dim);
`;

const StyledFreqSent = styled.div`
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
`;

const SentimentList = styled.ul`
  list-style-type: none;
  margin-top: var(--spacing-1);
`;

const SentimentItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-1);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacing-3);
  height: var(--spacing-3);
  margin-right: 9px;
`;

const StyledTopicPercentage = styled.div<StyledHighlightProps>`
  font: var(--body-text-x-small);

  ${({ highlighted = false }) => {
    if (highlighted) {
      return css`
        color: var(--white);
      `;
    }
    return css`
      color: var(--text-color-dim);
    `;
  }}
`;

interface CircleIconProps {
  color: string;
}

const Circle = styled.div<CircleIconProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-radius: var(--border-radius-large);
  width: 18px;
  height: 18px;
`;

const StyledTopicIcon = styled(Icon).attrs({
  size: 18,
})``;

const StyledZeroState = styled.div`
  padding: var(--spacing-6) 0;
`;

const StyledZeroStateText = styled.div`
  max-width: 600px;

  h2 {
    padding-bottom: var(--spacing-1);
  }
`;

const StyledZeroStateCards = styled.div`
  display: flex;
  gap: var(--spacing-4);
  padding: var(--spacing-8) 0 0;

  ${MediaQueries.phoneOnly} {
    flex-direction: column;
  }
`;

const StyledZeroStateCard = styled.article`
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  flex: 1 1;
`;

interface NavItemRange {
  start: moment.Moment;
  end: moment.Moment;
}

interface NavItem {
  display: string;
  range: NavItemRange;
  id: string;
}

interface NavItems {
  [key: string]: NavItem;
}

interface Tag {
  name: string;
  count: number;
}

interface TopTags {
  total_conversations: number;
  tags: Tag[];
}

interface TopicSentiments {
  positive: number;
  negative: number;
  neutral: number;
}

interface Topic {
  label: string;
  total: number;
  sentiments: TopicSentiments;
}

interface Category {
  name: string;
  topics: Topic[];
}

interface TopTopics {
  total_conversations: number;
  categories: Category[];
}

interface InsightsResult {
  top_tags: TopTags;
  top_topics: TopTopics;
}

interface SubsriberMetrics {
  total_subscribers: number;
  matching_subscribers: number;
}

interface ConversationMetrics {
  total_conversations: number;
  matching_conversations: number;
  active_conversations: number;
}

enum ConversationIntelligenceStatus {
  NO_CONVERSATIONS = 'NO_CONVERSATIONS',
  PARTIAL_INSIGHTS = 'PARTIAL_INSIGHTS',
  COMPLETE_INSIGHTS = 'COMPLETE_INSIGHTS',
}

const navItems: NavItems = {
  today: {
    display: 'Today',
    range: {
      start: moment().subtract(1, 'days'),
      end: moment(),
    },
    id: 'today',
  },
  last_7_days: {
    display: 'Last 7 Days',
    range: {
      start: moment().subtract(7, 'days'),
      end: moment(),
    },
    id: 'last_7_days',
  },
  last_30_days: {
    display: 'Last 30 Days',
    range: {
      start: moment().subtract(30, 'days'),
      end: moment(),
    },
    id: 'last_30_days',
  },
};
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const MAX_PAGE_SIZE = 20;

const ViewSelectorWrapper = styled.div`
  height: 24px;
  width: 252px;
  opacity: 1;
  position: absolute;
  z-index: 101;
  right: 54px;
  top: 13px;
  transition: opacity var(--nav-menu-transition-speed),
    transform var(--nav-menu-transition-speed);
`;

const getPayloadFromControls = (controls: Controls): InsightsPayload => {
  /**
   * This function converts the controls to a payload, making sure that empty array
   * and strings are not passed to the API e.g. shop_ids = []
   */
  const payload: InsightsPayload = {
    max_page_size: MAX_PAGE_SIZE,
    page: 0,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  payload.page = controls.page > 0 ? controls.page - 1 : 0;
  if (controls.maxPageSize) {
    payload.max_page_size = controls.maxPageSize;
  }

  if (controls.shopIds.length) {
    payload.shop_ids = controls.shopIds;
  }
  if (controls.agentIds.length) {
    payload.sales_agent_ids = controls.agentIds;
  }
  if (controls.filters.length) {
    payload.filters = controls.filters;
  }
  if (controls.searchText) {
    payload.search_text = controls.searchText;
  }
  if (controls.searchDirection) {
    payload.search_direction = controls.searchDirection;
  }
  if (controls.topicLabel) {
    payload.topic_label = controls.topicLabel;
  }
  if (controls.tag) {
    payload.tag = controls.tag;
  }
  if (controls.sortBy) {
    payload.sort_by = controls.sortBy;
  }
  if (controls.dateRangeStart) {
    payload.date_range_start = controls.dateRangeStart;
  }
  if (controls.dateRangeEnd) {
    payload.date_range_end = controls.dateRangeEnd;
  }

  return payload;
};

const updateHistoryFromControls = (history: any, controls: Controls) => {
  const newQueryParams = new URLSearchParams();
  if (controls.shopIds.length > 0) {
    newQueryParams.set('shop_ids', controls.shopIds.join(','));
  }
  if (controls.agentIds.length > 0) {
    newQueryParams.set('agent_ids', controls.agentIds.join(','));
  }
  if (controls.filters.length > 0) {
    newQueryParams.set('filters', controls.filters.join(','));
  }
  if (controls.searchText) {
    newQueryParams.set('search_text', controls.searchText);
  }
  if (controls.searchDirection) {
    newQueryParams.set('search_direction', controls.searchDirection);
  }
  if (controls.topicLabel) {
    newQueryParams.set('topic_label', controls.topicLabel);
  }
  if (controls.tag) {
    newQueryParams.set('tag', controls.tag);
  }
  if (controls.sortBy) {
    newQueryParams.set('sort_by', controls.sortBy);
  }
  if (controls.dateRangeStart) {
    newQueryParams.set('date_range_start', controls.dateRangeStart);
  }
  if (controls.dateRangeEnd) {
    newQueryParams.set('date_range_end', controls.dateRangeEnd);
  }
  if (controls.page) {
    newQueryParams.set('page', controls.page.toString());
  }
  history.push(`?${newQueryParams.toString()}`);
};

const formatDate = (inputDate: string): string => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dateObj = new Date(inputDate);
  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
};

const getStatusColor = (conversation: ConversationWithInsights) => {
  if (conversation.active) {
    return 'var(--green-2)';
  }

  if (
    moment(conversation.updated_at).isAfter(moment().subtract(10, 'minutes'))
  ) {
    return 'var(--blue-2)';
  }

  if (
    moment(conversation.expiry_at).isSameOrAfter(moment()) &&
    moment(conversation.expiry_at).isBefore(moment().add(1, 'hour'))
  ) {
    return 'var(--yellow-2)';
  }
  return 'var(--gray-3)';
};

const getStatusBody = (conversation: ConversationWithInsights) => {
  if (
    conversation.active &&
    moment(conversation.expiry_at).isSameOrAfter(moment().add(1, 'hour')) &&
    moment(conversation.expiry_at).isSameOrBefore(moment().add(47, 'hour'))
  ) {
    return `Happening now`;
  }

  if (
    conversation.active &&
    moment(conversation.updated_at).isAfter(moment().subtract(10, 'minutes'))
  ) {
    const updatedAt = moment.utc(conversation.updated_at);
    const duration = moment.duration(moment().diff(updatedAt));
    const minutesAgo = Math.floor(duration.asMinutes());
    return `${minutesAgo} min ago`;
  }

  if (
    conversation.active &&
    moment(conversation.expiry_at).isSameOrAfter(moment()) &&
    moment(conversation.expiry_at).isBefore(moment().add(1, 'hour'))
  ) {
    return `Expiring soon`;
  }
  return `Inactive ${formatDate(conversation.closed_at)}`;
};

const getActivityBody = (conversation: ConversationWithInsights) => {
  return (
    <>
      <Icon
        color={getStatusColor(conversation)}
        component={IconSet.Disc}
        size={18}
      />
      <BodyText size="x-small">{getStatusBody(conversation)}</BodyText>
    </>
  );
};

const getEngagementBody = (conversation: ConversationWithInsights) => {
  const messageCount = conversation.messages.length;
  const unsubscribed =
    conversation.outcomes && conversation.outcomes[0]
      ? conversation.outcomes[0]?.type === 'subscriber/opt_out'
      : false;
  let engagementIconColor = 'var(--blue-5)';
  let engagementIcon = IconSet.TwoWayConvo;
  let engagementText = `${messageCount} message${messageCount > 1 ? 's' : ''}`;

  if (messageCount >= 6 && !unsubscribed) {
    engagementIconColor = 'var(--orange-4)';
    engagementIcon = IconSet.Bolt;
    engagementText = `Engaged (${engagementText})`;
  }
  if (unsubscribed) {
    engagementIconColor = 'var(--red-core)';
    engagementIcon = IconSet.Forbid;
    engagementText = `Unsubscribed (${engagementText})`;
  }

  return (
    <>
      <Icon color={engagementIconColor} component={engagementIcon} size={18} />
      <BodyText size="x-small">{engagementText}</BodyText>
    </>
  );
};

const getLogline = (conversation: ConversationWithInsights) => {
  return (
    <StyledLogLine>
      <StyledLogLineItem>{getActivityBody(conversation)}</StyledLogLineItem>
      <StyledLogLineItem>{getEngagementBody(conversation)}</StyledLogLineItem>
    </StyledLogLine>
  );
};

const removeMerchantNameAndDuplicates = (
  tags: TopTags,
  shopName: string,
): TopTags => {
  const cleanString = (str: string): string => {
    return str
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .trim();
  };

  const cleanShopName = cleanString(shopName);
  const tagMap = new Map<string, Tag>();
  const excludedTags = ['positive', 'neutral', 'negative'];

  tags.tags.forEach((tag) => {
    const cleanTagName = cleanString(tag.name);

    if (
      cleanTagName.includes(cleanShopName) ||
      excludedTags.includes(cleanTagName)
    ) {
      return;
    }

    if (tagMap.has(cleanTagName)) {
      // Merge duplicate tags
      const existingTag = tagMap.get(cleanTagName);
      if (existingTag) {
        existingTag.count += tag.count;
      }
    } else {
      // Add new tag to the map
      tagMap.set(cleanTagName, { ...tag });
    }
  });

  const result = {
    total_conversations: tags.total_conversations,
    tags: Array.from(tagMap.values()),
  };

  return result;
};

const getTopTags = (
  result: InsightsResult | undefined,
  shopName: string,
): { tag: string; percentage: number }[] => {
  if (!result) return [];
  const { top_tags } = result;

  const topTags = removeMerchantNameAndDuplicates(top_tags, shopName);

  const totalConversations = topTags.total_conversations;
  const sortedTags = topTags.tags.sort((a, b) => b.count - a.count);
  return sortedTags.map((knownTag) => {
    const roundedPercentage =
      Math.round((knownTag.count / totalConversations) * 10000) / 100;
    return {
      tag: knownTag.name,
      percentage: roundedPercentage,
    };
  });
};

const sortTopTopics = (topTopics: TopTopics): Category[] => {
  // Step 1: Sort topics within each category by their total in descending order
  const sortedCategories = topTopics.categories.map((category) => {
    const sortedTopics = category.topics.sort((a, b) => b.total - a.total);
    return { ...category, topics: sortedTopics };
  });

  // Step 2: Sort categories by the highest total topic within each category in descending order
  sortedCategories.sort((a, b) => {
    const highestTotalA = a.topics[0]?.total || 0;
    const highestTotalB = b.topics[0]?.total || 0;
    return highestTotalB - highestTotalA;
  });

  return sortedCategories;
};

const getTopTopics = (result: InsightsResult | undefined): Category[] => {
  if (!result) return [];
  const { top_topics } = result;

  return sortTopTopics(top_topics);
};

const adjustTrendsDataForUTC = (
  data: TopicLabelTrendGroup[],
  numberOfDays: number,
) => {
  if (numberOfDays === 1 && data.length > 1) {
    return [
      {
        group: data[data.length - 1].group,
        total_conversations: data.reduce(
          (acc, group) => acc + group.total_conversations,
          0,
        ),
        total_conversations_for_topic_label: data.reduce(
          (acc, group) => acc + group.total_conversations_for_topic_label,
          0,
        ),
      },
    ];
  }
  return data;
};

const formatPercentage = (numerator: number, denominator: number): string => {
  return (Math.round((numerator / denominator) * 10000) / 100).toFixed(2);
};

const FOCUSABLE_ELEMENTS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

const returnFocusToActivator = (activator: HTMLElement) => {
  if (typeof activator?.focus === 'function') {
    activator.focus();
  }
};

const setFocus = (
  modalRef: React.RefObject<HTMLDivElement>,
  firstElementRef?: React.RefObject<HTMLElement>,
) => {
  if (firstElementRef?.current) {
    firstElementRef.current.focus();
    return;
  }
  if (modalRef.current) {
    const firstFocusableElement = modalRef.current.querySelector(
      FOCUSABLE_ELEMENTS,
    ) as HTMLElement;
    firstFocusableElement?.focus();
  }
};

const trapFocus = (
  event: KeyboardEvent,
  modalRef: React.RefObject<HTMLDivElement>,
) => {
  if (!modalRef.current || event.key !== 'Tab') return;

  const focusable = modalRef.current.querySelectorAll(FOCUSABLE_ELEMENTS);
  const firstFocusable = focusable[0];
  const lastFocusable = focusable[focusable.length - 1];

  if (event.shiftKey && document.activeElement === firstFocusable) {
    event.preventDefault();
    (lastFocusable as HTMLElement).focus();
  }

  if (!event.shiftKey && document.activeElement === lastFocusable) {
    event.preventDefault();
    (firstFocusable as HTMLElement).focus();
  }
};

const ConversationIntelligence = (): JSX.Element => {
  const chatDrawerRef = useRef<HTMLDivElement>(null);
  const [drawerActivator, setDrawerActivator] = useState<Element | null>(null);

  const isMultiShop = window.location.href.includes('workspace');
  const queryParams = new URLSearchParams(window.location.search);

  const user = useUser();

  const queryShops = queryParams.get('shop_ids') ?? '';
  const currentShopId = getShopId();
  const shopIds = isMultiShop
    ? queryShops
        .split(',')
        .filter((shopId) => shopId !== '')
        .map(parseInt)
    : [currentShopId];

  const queryAgents = queryParams.get('agent_ids') ?? '';
  const agentIds = queryAgents
    .split(',')
    .filter((agentId) => agentId !== '')
    .map(parseInt);

  const searchText = queryParams.get('search_text') ?? '';
  const searchDirection = queryParams.get('search_direction') ?? 'ALL';
  const topicLabel = queryParams.get('topic_label') ?? '';
  const page = parseInt(queryParams.get('page') ?? '0');
  const maxPageSize = parseInt(
    queryParams.get('maxPageSize') ?? MAX_PAGE_SIZE.toString(),
  );

  const queryFilters = queryParams.get('filters') ?? '';
  const filters = queryFilters.split(',').filter((filter) => filter !== '');

  const sortBy = queryParams.get('sort_by') ?? MOST_RECENT_INBOUND;
  const dateRangeStart =
    queryParams.get('date_range_start') ??
    moment().subtract(30, 'days').format(DATE_FORMAT);
  const dateRangeEnd =
    queryParams.get('date_range_end') ?? moment().format(DATE_FORMAT);

  const messageThreadRef = useRef<HTMLUListElement | null>(null);
  const [subscriberId, setSubscriberId] = useState<number | undefined>(
    undefined,
  );

  const [searchControlsAreOpen, setSearchControlsAreOpen] = useState(false);

  const [controls, setControls] = useState<Controls>({
    shopIds,
    agentIds,
    searchText,
    searchDirection,
    filters,
    topicLabel,
    sortBy,
    dateRangeEnd,
    dateRangeStart,
    page,
    maxPageSize,
  });
  const [summaries, setSummaries] = useState<
    ConversationsWithInsightsResponse | undefined
  >({
    conversations: [],
    users: [],
    matching_conversations: 0,
    total_conversations: 0,
    active_conversations: 0,
    matching_unique_subscribers: 0,
    total_unique_subscribers: 0,
  });
  const [subsrciberMetrics, setSubscriberMetrics] = useState<SubsriberMetrics>({
    total_subscribers: 0,
    matching_subscribers: 0,
  });

  const [conversationMetrics, setConversationMetrics] =
    useState<ConversationMetrics>({
      total_conversations: 0,
      matching_conversations: 0,
      active_conversations: 0,
    });
  const [topicLabelTrends, setTopicLabelTrends] = useState<TopicLabelTrends>({
    trends: [],
  });
  const [insights, setInsights] = useState<InsightsResult>();
  const [selectedTabGroup, setSelectedTabGroup] = useState<string>('topics');
  const [conversationIntelligenceStatus, setConversationIntelligenceStatus] =
    useState<ConversationIntelligenceStatus>(
      ConversationIntelligenceStatus.COMPLETE_INSIGHTS,
    );
  const startDate = moment(dateRangeStart, DATE_FORMAT);
  const endDate = moment(dateRangeEnd, DATE_FORMAT);
  const numDays = endDate.diff(startDate, 'days');
  const rangeIdMapping: { [key: number]: string } = {
    1: navItems.today.id,
    7: navItems.last_7_days.id,
    30: navItems.last_30_days.id,
  };
  const [selectedDateId, setSelectedDateId] = useState<string | null>(
    rangeIdMapping[numDays] || null,
  );
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const { data: subscriber } = useSubscriber(subscriberId);
  const [isConversationsLoading, setIsConversationsLoading] = useState(false);
  const [areSubscriberMetricsLoading, setAreSubscriberMetricsLoading] =
    useState(true);
  const [areConversationMetricsLoading, setAreConversationMetricsLoading] =
    useState(true);
  const [isTopicLabelTrendsLoading, setIsTopicLabelTrendsLoading] =
    useState(false);
  const [isTopTagsLoading, setIsTopTagsLoading] = useState(false);
  const [isTagsExpanded, setIsTagsExpanded] = useState(false);
  const { data: salesShopsData } = useSalesShopData();
  const { view } = useView();

  const [activeCardTab, setActiveCardTab] = useState('merchant');
  const handleCardTabClick = (tabId: 'merchant' | 'debug') => {
    setActiveCardTab(tabId);
  };

  const clearConversation = () => {
    setSubscriberId(undefined);
    setSelectedCardId(null);
  };

  /* chat drawer keyboard a11y */
  useEffect(() => {
    if (!selectedCardId) {
      returnFocusToActivator(drawerActivator as HTMLElement);
      return;
    }

    setDrawerActivator(document.activeElement);
    setFocus(chatDrawerRef);

    const eventHandlers = (event: KeyboardEvent) => {
      trapFocus(event, chatDrawerRef);
      if (event.key === 'Escape') {
        clearConversation();
      }
    };

    window.addEventListener('keydown', eventHandlers);

    return () => {
      window.removeEventListener('keydown', eventHandlers);
    };
  }, [selectedCardId]);
  /* end chat a11y */

  const [showNoInsightsBanner, setShowNoInsightsBanner] = useState(() => {
    return localStorage.getItem('insightsBannerDismissed') !== 'true';
  });

  const dismissNoInsightsBanner = () => {
    setShowNoInsightsBanner(false);
    localStorage.setItem('insightsBannerDismissed', 'true');
  };

  const history = useHistory();

  useEffect(() => {
    if (isMultiShop && user.user.shop_id !== 1) {
      toast.error('Must be logged in as shop 1');
      return;
    }
    updateHistoryFromControls(history, controls);

    const fetchConversationIntelligencStatus = async () => {
      const route = '/v2/convert/shop-conversation-intelligence-status';
      const response = await api.get(route);
      setConversationIntelligenceStatus(response.status);
    };

    const fetchConversations = async () => {
      const route = isMultiShop
        ? '/v2/convert/conversations'
        : '/v2/convert/shop-conversations';
      setIsConversationsLoading(true);
      const response = await api.post(route, getPayloadFromControls(controls));
      setSummaries(response);
      setIsConversationsLoading(false);
    };

    const fetchSubscriberMetrics = async () => {
      const route = isMultiShop
        ? '/v2/convert/subscriber-metrics'
        : '/v2/convert/shop-subscriber-metrics';
      setAreSubscriberMetricsLoading(true);
      const response = await api.post(route, getPayloadFromControls(controls));
      setSubscriberMetrics(response);
      setAreSubscriberMetricsLoading(false);
    };

    const fetchConversationMetrics = async () => {
      const route = isMultiShop
        ? '/v2/convert/conversation-metrics'
        : '/v2/convert/shop-conversation-metrics';
      setAreConversationMetricsLoading(true);
      const response = await api.post(route, getPayloadFromControls(controls));
      setConversationMetrics(response);
      setAreConversationMetricsLoading(false);
    };

    const fetchTopicLabelTrends = async () => {
      const route = isMultiShop
        ? '/v2/convert/topic-label-trends'
        : '/v2/convert/shop-topic-label-trends';
      setIsTopicLabelTrendsLoading(true);
      const response = await api.post(route, getPayloadFromControls(controls));
      setTopicLabelTrends(response);
      setIsTopicLabelTrendsLoading(false);
    };

    const fetchTopTags = async () => {
      const route = isMultiShop
        ? '/v2/convert/insights'
        : '/v2/convert/shop-insights';
      setIsTopTagsLoading(true);
      const response = await api.post(route, getPayloadFromControls(controls));
      setInsights(response);
      setIsTopTagsLoading(false);
    };

    fetchConversationIntelligencStatus();
    fetchConversations();
    fetchSubscriberMetrics();
    fetchConversationMetrics();
    fetchTopicLabelTrends();
    fetchTopTags();
  }, [controls]);

  /* LogRocket */
  const {
    user: { id: userId, username, shop_id: shopId, is_admin: isAdmin },
  } = useUser();

  useEffect(() => {
    if (!userId) return;

    if (window.location.host !== 'app.postscript.io') return;

    LogRocket.init('ssovkv/conversation-intelligence');

    LogRocket.identify(userId.toString(), {
      name: username,
      jwt_shop_id: shopId,
      is_admin: isAdmin,
    });
  }, [userId]);

  // auto scroll to bottom of message thread
  useEffect(() => {
    if (messageThreadRef.current) {
      const conversation = summaries?.conversations.find(
        (conversation) => conversation.id === selectedCardId,
      );
      const firstMessageId =
        conversation?.messages[0].oid ??
        conversation?.messages[0].external_id ??
        '';
      const targetMessage = messageThreadRef.current.querySelector(
        `#message-${subscriberId}_${firstMessageId}`,
      );

      if (targetMessage) {
        targetMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [subscriber]);

  // TODO: Lets move this into it's own component
  const dateRangeTabGroup = () => {
    return (
      <TabGroup description="Select a date range" id="insights-date-tabgroup">
        {Object.keys(navItems).map((item) => {
          const { start, end } = navItems[item].range;

          return (
            <button
              data-selected={navItems[item].id === selectedDateId}
              key={item}
              onClick={() => {
                const formattedFrom = start.format(DATE_FORMAT);
                const formattedTo = end.format(DATE_FORMAT);
                setControls({
                  ...controls,
                  dateRangeStart: formattedFrom,
                  dateRangeEnd: formattedTo,
                });
                setSelectedDateId(navItems[item].id);
              }}
              data-cy={`insights-date-nav-button-${navItems[item].display
                .replace(/\s/g, '')
                .toLowerCase()}`}
              type="button"
            >
              {navItems[item].display}
            </button>
          );
        })}
      </TabGroup>
    );
  };

  const selectedDateRangeLabel = () => {
    const label = `in the last ${numDays} days`;
    if (selectedDateId && selectedDateId === 'today') {
      return 'today';
    }
    return label;
  };

  const formatNumberWithCommas = (numberString: string): string => {
    const number = parseInt(numberString, 10);
    return new Intl.NumberFormat('en-US').format(number);
  };

  const formatPhoneNumber = (phoneNumber: string | undefined): string => {
    if (!phoneNumber) return '';
    const cleaned = String(phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  const formatTopicLabel = (label: string): string => {
    const lowerCaseLabel = label.trim().toLowerCase();
    const sentenceCase = `${lowerCaseLabel
      .charAt(0)
      .toUpperCase()}${lowerCaseLabel.slice(1)}`;
    return sentenceCase;
  };

  const getIdentifier = (conversation: ConversationWithInsights) => {
    const formattedName =
      conversation.subscriber?.first_name && conversation.subscriber?.last_name
        ? `${
            conversation.subscriber.first_name
          } ${conversation.subscriber.last_name[0].toUpperCase()}`
        : '';

    const formattedPhoneNumber =
      conversation.subscriber?.phone_number &&
      formatPhoneNumber(conversation.subscriber?.phone_number);

    const identifier =
      (formattedName || formattedPhoneNumber) &&
      `${formattedName || ''}${formattedName && formattedPhoneNumber && ' · '}${
        formattedPhoneNumber || ''
      }`;

    return identifier;
  };

  const generateInsights = async (conversation: ConversationWithInsights) => {
    const response = await api.post(
      `/v2/convert/conversations/${conversation.id}/generate-insights`,
    );
    if (response.ok) {
      window.location.reload();
    } else {
      alert(response.message);
    }
  };

  const renderConversationCard = (conversation: ConversationWithInsights) => {
    // Using first insight in list (expected to be the only one)
    const insight =
      conversation.insights && conversation.insights.length > 0
        ? conversation.insights[0]
        : undefined;

    const converted =
      conversation.outcomes && conversation.outcomes[0]
        ? conversation.outcomes[0]?.type === 'order/create'
        : false;

    const identifier = getIdentifier(conversation);

    const participatingUsers: { [id: number]: ConversationIntelligenceUser } =
      {};

    if (summaries && summaries.users) {
      summaries.users.forEach((user) => {
        participatingUsers[user.id] = user;
      });
    }
    const participantNamesSet = new Set<string>();

    conversation.messages
      .map((message) => message.user_id)
      .filter((userId) => userId !== null)
      .forEach((userId) => {
        if (!userId) {
          return;
        }

        if (
          !participatingUsers[userId] ||
          !participatingUsers[userId].first_name ||
          !participatingUsers[userId].last_name
        ) {
          return;
        }
        const { first_name: firstName, last_name: lastName } =
          participatingUsers[userId];
        const name = `${firstName} ${
          lastName && lastName.length > 0 && lastName[0]
        }.`;
        participantNamesSet.add(name);
      });

    const participantNames: string[] = Array.from(participantNamesSet);

    // The following approach makes sure that topic labels are rendered
    // at the top and tags are rendered next only if they are not an existing topic label
    const topicLabelsAndTags: Set<string> = new Set();
    if (conversation.insights && conversation.insights[0]) {
      conversation.insights[0].topic_labels.forEach((topicLabel) => {
        if (
          'rank' in topicLabel &&
          topicLabel.rank <= MAX_TOPIC_LABEL_RANK &&
          topicLabel.version === TOPIC_LABEL_VERSION
        ) {
          topicLabelsAndTags.add(topicLabel.label);
        }
      });

      conversation.insights[0].labels.forEach((label) => {
        topicLabelsAndTags.add(label);
      });
    }

    const hasInsights = topicLabelsAndTags.size > 0;

    const CIRCLE_ICON_SIZE = 14;
    const sentimentIcons: {
      [sentiment: string]: { icon: JSX.Element; label: string };
    } = {
      positive: {
        icon: (
          <Circle color="var(--color-sentiment-icon-positive)">
            <Icon
              color="var(--color-sentiment-text-positive)"
              component={IconSet.FaceSmile}
              size={CIRCLE_ICON_SIZE}
            />
          </Circle>
        ),
        label: 'Positive',
      },
      neutral: {
        icon: (
          <Circle color="var(--color-sentiment-icon-neutral)">
            <Icon
              color="var(--color-sentiment-text-neutral)"
              component={IconSet.FaceStraight}
              size={CIRCLE_ICON_SIZE}
            />
          </Circle>
        ),
        label: 'Neutral',
      },
      negative: {
        icon: (
          <Circle color="var(--color-sentiment-icon-negative)">
            <Icon
              color="var(--color-sentiment-text-negative)"
              component={IconSet.FaceSad}
              size={CIRCLE_ICON_SIZE}
            />
          </Circle>
        ),
        label: 'Negative',
      },
      none: {
        icon: (
          <Circle color="var(--color-sentiment-icon-neutral)">
            <Icon
              color="var(--color-sentiment-text-neutral)"
              component={IconSet.Forbid2}
              size={CIRCLE_ICON_SIZE}
            />
          </Circle>
        ),
        label: 'No sentiment available',
      },
    };

    const cardContent =
      insight && insight.summary ? (
        <BodyText style={{ flex: '1' }}>{insight.summary}</BodyText>
      ) : (
        <ConversationCardNoSummaryBody
          conversation={conversation}
          participatingUsers={participatingUsers}
          isMultiShop={isMultiShop}
          truncate
        />
      );

    // Function to render tags
    const renderTags = (tags: string[]) => {
      return tags.map((tag) => {
        if (tag.startsWith('assistant_thread_id_')) {
          // Extract thread ID from the tag
          const threadId = tag.replace('assistant_thread_id_', '');
          const link = `https://platform.openai.com/threads/${threadId}`;
          return (
            <BodyText size="small">
              <a href={link} target="_blank" rel="noopener noreferrer">
                {tag}
              </a>
            </BodyText>
          );
        }
        return <BodyText size="small">{tag}</BodyText>;
      });
    };

    return (
      <StyledSummaryCard
        className={`${selectedCardId === conversation.id ? 'active' : ''} ${
          !hasInsights && 'no-insights'
        }`}
        key={conversation.id}
        data-testid="summary-card"
      >
        <div>
          <StyledSumaryCardLeftCol>
            <Layout vertical gap="var(--spacing-2)">
              {/* Badge */}
              {isMultiShop && (
                <Badge
                  style={{
                    backgroundColor: salesShopsData
                      ? salesShopsData.shop_data.find(
                          (shop) => shop.id === conversation.shop?.id,
                        )?.sales_shop_color
                      : undefined,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    color: salesShopsData
                      ? salesShopsData.shop_data.find(
                          (shop) => shop.id === conversation.shop?.id,
                        )?.sales_shop_text_color
                      : undefined,
                  }}
                >
                  {conversation.shop?.name || 'Unknown Shop'}
                </Badge>
              )}

              {/* Header */}
              <StyledSummaryHeader>
                {identifier && <Heading size="x-small">{identifier}</Heading>}
                {getLogline(conversation)}
              </StyledSummaryHeader>
            </Layout>

            {/* Debug Panel */}
            {isBubAI() && (
              <>
                <StyledCardTabGroup
                  description="Choose a view level"
                  id={`summary-card-view-${conversation.id}`}
                  placement="container"
                >
                  <button
                    data-selected={activeCardTab === 'merchant'}
                    data-tabpanel={`card-insights-${conversation.id}`}
                    id={`card-insights-${conversation.id}-tab`}
                    onClick={() => handleCardTabClick('merchant')}
                    type="button"
                  >
                    Merchant View
                  </button>
                  <button
                    data-selected={activeCardTab === 'debug'}
                    data-tabpanel={`card-debug-${conversation.id}`}
                    id={`card-debug-${conversation.id}-tab`}
                    onClick={() => handleCardTabClick('debug')}
                    type="button"
                  >
                    Debug View
                  </button>
                </StyledCardTabGroup>

                <StyledCardTabPanel
                  id={`card-insights-${conversation.id}`}
                  selected={activeCardTab === 'debug'}
                >
                  <StyledDebugContents>
                    {conversation.tags && (
                      <BodyText size="small">
                        Conversation Tags: {renderTags(conversation.tags)}
                      </BodyText>
                    )}
                  </StyledDebugContents>
                </StyledCardTabPanel>
              </>
            )}

            {/* Conversation / Summary */}
            {isBubAI() ? (
              <StyledCardTabPanel
                id={`card-insights-${conversation.id}`}
                selected={activeCardTab === 'merchant'}
              >
                {cardContent}
              </StyledCardTabPanel>
            ) : (
              cardContent
            )}

            {/* Buttons */}
            <StyledCardButtons>
              <Button
                className="view-conversation-button"
                icon={IconSet.TwoWayConvo}
                onClick={() => {
                  setSubscriberId(conversation.subscriber?.id);
                  setSelectedCardId(conversation.id);
                }}
                monochrome
                variant="text"
                size="small"
                data-testid="view-conversation"
              >
                View Conversation
              </Button>
              <ButtonAnchor
                className="subscriber-details-button"
                icon={IconSet.User}
                href={`${window.location.origin}/subscribers/${conversation.subscriber?.id}`}
                target="_blank"
                monochrome
                variant="text"
                size="small"
              >
                Subscriber Detail
              </ButtonAnchor>
            </StyledCardButtons>
          </StyledSumaryCardLeftCol>

          <StyledSumaryCardRightCol>
            <StyledHashtags>
              {/* Agent */}
              {isMultiShop && participantNames.length > 0 && (
                <StyledCardSidebarItem>
                  <Circle color="var(--highlight-color)">
                    <Icon
                      color="var(--white)"
                      component={IconSet.Bub}
                      size={CIRCLE_ICON_SIZE}
                    />
                  </Circle>
                  <span>{participantNames.join(',')}</span>
                </StyledCardSidebarItem>
              )}

              {/* Order */}
              {hasInsights && (
                <StyledCardSidebarItem>
                  <Circle
                    color={converted ? 'var(--green-2)' : 'var(--gray-3)'}
                  >
                    <Icon
                      color={converted ? 'var(--green-8)' : 'var(--black)'}
                      component={IconSet.Dollar2}
                      size={CIRCLE_ICON_SIZE}
                    />
                  </Circle>
                  <span>{converted ? 'Converted' : 'Did not convert'}</span>
                </StyledCardSidebarItem>
              )}

              {/* Sentiment */}
              {hasInsights &&
                (insight && insight.sentiment ? (
                  <StyledCardSidebarItem>
                    {sentimentIcons[insight.sentiment.toLowerCase()].icon}
                    <span>
                      {sentimentIcons[insight.sentiment.toLowerCase()].label}
                    </span>
                  </StyledCardSidebarItem>
                ) : (
                  <StyledCardSidebarItem>
                    {sentimentIcons.none.icon}
                    <span>{sentimentIcons.none.label}</span>
                  </StyledCardSidebarItem>
                ))}

              {/* Tags and Topics */}
              {hasInsights ? (
                Array.from(topicLabelsAndTags).map((value) => {
                  return (
                    <StyledTopic
                      key={value}
                      tabIndex={0}
                      onClick={() =>
                        setControls({
                          ...controls,
                          topicLabel: value,
                        })
                      }
                      role="button"
                      href="#"
                    >
                      <StyledTopicIcon
                        component={IconSet.Hashtag}
                        color="var(--blue-5)"
                      />

                      {formatTopicLabel(value)}
                    </StyledTopic>
                  );
                })
              ) : (
                <StyledCardEmptyState
                  description="Insights not yet available"
                  size="small"
                  primaryAction={
                    isBubAI()
                      ? {
                          text: 'Generate Insights',
                          onClick: () => generateInsights(conversation),
                          monochrome: true,
                          size: 'small',
                          variant: 'secondary',
                        }
                      : undefined
                  }
                />
              )}
            </StyledHashtags>
          </StyledSumaryCardRightCol>
        </div>
      </StyledSummaryCard>
    );
  };

  const renderPaginationControls = () => {
    if (conversationMetrics.matching_conversations === 0) {
      return;
    }
    return (
      <Pagination
        currentPage={controls.page}
        selectPage={(selectedPage) => {
          return setControls({
            ...controls,
            page: selectedPage,
          });
        }}
        size="large"
        showFirstLastPageButtons
        totalPages={Math.ceil(
          conversationMetrics.matching_conversations / MAX_PAGE_SIZE,
        )}
      />
    );
  };

  if (!summaries) {
    return <StyledSkeletonCardTall />;
  }

  const isLoading =
    isConversationsLoading || isTopTagsLoading || isTopicLabelTrendsLoading;

  const copyConversationLinkToClipboard = () => {
    const url = `${
      window.location.origin + window.location.pathname
    }?search_text=${selectedCardId}`;
    navigator.clipboard.writeText(url);
    toast.success('Copied.');
  };

  const copyConversationToClipboard = () => {
    const conversation = summaries.conversations.find(
      (conversation) => selectedCardId === conversation.id,
    );
    if (!conversation) {
      toast.error('Cannot copy conversation to clipboard');
      return;
    }
    const text = conversation.messages
      .map((message, index, messages) => {
        const previousDirection =
          index > 0 ? messages[index - 1].direction : null;
        const messageDirection =
          message.direction === 'INBOUND' ? 'INBOUND' : 'OUTBOUND';

        const extraNewline = previousDirection !== messageDirection ? '\n' : '';

        return `${extraNewline}${messageDirection}: ${message.body}`;
      })
      .join('\n');
    navigator.clipboard.writeText(text);
    toast.success('Copied.');
  };

  const triggerJSONDownload = async () => {
    const route = '/v2/convert/json-conversations';

    try {
      const response: { signed_url: Location } = await api.post(
        route,
        getPayloadFromControls(controls),
      );
      window.location = response.signed_url;
    } catch (error) {
      toast.error('Error downloading JSON:', error);
    }
  };

  // since has_summary is a non-listed filter,
  // duplicate of filters without has_summary
  const listableFilters = controls.filters?.filter(
    (filter) => filter !== 'HAS_SUMMARY',
  );
  const hasListableFilters = listableFilters.length > 0;
  const filtersSelected = !!(
    controls.topicLabel ||
    controls.tag ||
    hasListableFilters
  );

  const badgeElements = (location: 'page' | 'modal') => {
    return (
      <>
        {controls.topicLabel && (
          <Badge
            icon={location === 'modal' ? IconSet.Close : undefined}
            iconAction={
              location === 'modal'
                ? () => setControls({ ...controls, topicLabel: undefined })
                : undefined
            }
            variant="success"
          >
            #{controls.topicLabel}
          </Badge>
        )}
        {controls.tag && (
          <Badge
            icon={location === 'modal' ? IconSet.Close : undefined}
            iconAction={
              location === 'modal'
                ? () => setControls({ ...controls, tag: undefined })
                : undefined
            }
            variant={isBubAI() ? 'success' : 'info'}
          >
            #{controls.tag}
          </Badge>
        )}
        {location === 'page' &&
          hasListableFilters &&
          listableFilters.map((filter) => {
            const label = FILTER_LABEL_LOOKUP[filter]?.label || filter;
            return <Badge>{label}</Badge>;
          })}
        {location === 'page' && !filtersSelected && (
          <BodyText size="x-small">Click to open search</BodyText>
        )}
      </>
    );
  };

  let titleText = <>Viewing all conversations {selectedDateRangeLabel()}</>;

  const hasNonSpecialCasedFilters =
    (controls.filters.length === 1 && controls.filters[0] !== HAS_SUMMARY) ||
    controls.filters.length > 1;

  if (controls.searchText) {
    titleText = (
      <>
        Viewing conversations mentioning{' '}
        <Highlight>{controls.searchText}</Highlight>
      </>
    );
  } else if (hasNonSpecialCasedFilters || controls.topicLabel || controls.tag) {
    titleText = <>Viewing conversations matching filter criteria</>;
  }

  const adjustedTrendChartData = adjustTrendsDataForUTC(
    topicLabelTrends.trends,
    numDays,
  );

  const chartLabelYears = new Set(
    adjustedTrendChartData.map(
      (trend) => moment(trend.group, ['WW-YYYY', 'MM-DD-YYYY']).year,
    ),
  );
  const addYearToChartLabels = chartLabelYears.size > 1;
  const chartLabels = adjustedTrendChartData.map((trend) => {
    const parsedDate: Moment = moment(trend.group, ['WW-YYYY', 'MM-DD-YYYY']);
    return parsedDate.format(addYearToChartLabels ? 'MMM DD, YYYY' : 'MMM DD');
  });

  // Color getters for ChartJS
  const colorTopicPrimary = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-primary`);

  const colorTopicPrimaryHover = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-primary-hover`);

  const colorTopicSecondary = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-secondary`);

  const colorTopicSecondaryHover = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-secondary-hover`);

  const borderColorExtraDim = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--border-color-extra-dim`);

  const borderColorDim = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--border-color-dim`);

  const textColorDim = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--text-color-dim`);

  const bodyTextTypeface = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--body-text-typeface`);

  const colorBackgroundTooltip = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-background-product-tooltip`);

  const colorTextTooltip = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-text-product-tooltip`);

  const shouldRenderLineChart =
    controls.topicLabel ||
    controls.tag ||
    controls.searchText ||
    (controls.filters.length === 1 && controls.filters[0] !== HAS_SUMMARY) ||
    controls.filters.length > 1;

  const datasets: Array<any> = [
    {
      label: 'Total Conversations',
      data: adjustedTrendChartData.map((trend) => trend.total_conversations),
      fill: false,
      type: 'bar',
      barPercentage: 0.96,
      borderColor: 'transparent',
      borderWidth: 1,
      borderSkipped: 'bottom',

      // styles
      backgroundColor: shouldRenderLineChart
        ? colorTopicSecondary
        : colorTopicPrimary,
      hoverBackgroundColor: shouldRenderLineChart
        ? colorTopicSecondaryHover
        : colorTopicPrimaryHover,
      maxBarThickness: 30,
    },
  ];

  if (shouldRenderLineChart) {
    datasets.unshift({
      // TODO: Update this since it's now topic label, search text and filters
      label: `Conversations for ${controls.topicLabel}`,
      data: adjustedTrendChartData.map(
        (trend) => trend.total_conversations_for_topic_label,
      ),
      fill: false,
      type: 'line',

      // styles
      backgroundColor: colorTopicPrimary,
      borderColor: colorTopicPrimary,
      borderDash: [6, 4],
      borderDashOffset: 20,
      borderWidth: 1,
      hoverBackgroundColor: colorTopicPrimaryHover,
      hoverBorderColor: colorTopicPrimaryHover,
      lineTension: 0,
      pointBackgroundColor: colorTopicPrimary,
      pointHitRadius: 6,
    });
  }
  const chartData = {
    labels: chartLabels,
    datasets,
  };
  const topTagsToDisplay = getTopTags(insights, user.user.shop_name);
  const topTopicsToDisplay = getTopTopics(insights);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 1,
    height: 88,
    scales: {
      height: 99,
      xAxes: [
        {
          gridLines: {
            color: 'transparent',
            zeroLineColor: borderColorDim,
          },
          ticks: {
            beginAtZero: true,
            fontColor: textColorDim,
            fontFamily: bodyTextTypeface,
            fontSize: 12,
            padding: 0,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderSkipped: 'bottom',
            color: borderColorExtraDim,
            zeroLineColor: borderColorDim,
            offsetGridLines: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 50,
            fontColor: textColorDim,
            fontFamily: bodyTextTypeface,
            fontSize: 12,
            padding: 3,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        defaultFontColor: 'red',
        defaultFontSize: 100,
      },
    },
    tooltips: {
      enabled: true,
      backgroundColor: colorBackgroundTooltip,
      titleFontFamily: bodyTextTypeface,
      titleFontSize: 14,
      titleFontStyle: 'bold',
      titleFontColor: colorTextTooltip,

      bodyFontFamily: bodyTextTypeface,
      bodyFontSize: 12,
      bodyFontStyle: 'normal',
      bodyFontColor: colorTextTooltip,
      borderColor: 'transparent',
      borderWidth: 0,
      xPadding: 12,
      yPadding: 6,
      cornerRadius: 6,
      displayColors: false,
    },
  };

  const conversation = summaries.conversations.find(
    (conversation) => selectedCardId === conversation.id,
  );
  const identifier = conversation ? getIdentifier(conversation) : '';

  const toggleHasSummaryFilter = () => {
    setControls((prevControls) => {
      const updatedFilters = prevControls.filters.includes(HAS_SUMMARY)
        ? prevControls.filters.filter((filter) => filter !== HAS_SUMMARY)
        : [...prevControls.filters, HAS_SUMMARY];

      return {
        ...prevControls,
        filters: updatedFilters,
      };
    });
  };

  /* ZERO STATE */
  const noConversations =
    conversationIntelligenceStatus ===
    ConversationIntelligenceStatus.NO_CONVERSATIONS;

  /* PARTIAL STATE BANNER */
  const noInsightsReady =
    conversationIntelligenceStatus ===
    ConversationIntelligenceStatus.PARTIAL_INSIGHTS;
  const noInsightsBanner = (
    <Banner
      bodyText="Summaries, tags, and sentiments are available 48 hours after each conversation ends. Topic insights begin generating after 500-1000 conversations."
      heading="Your insights will be available here soon"
      onDismiss={dismissNoInsightsBanner}
      variant="guidance"
    />
  );

  const areMetricsVisible =
    !areSubscriberMetricsLoading || !areConversationMetricsLoading;
  const metricsContent = (
    <DataCardLayout>
      {areConversationMetricsLoading ? (
        <StyledSkeletonCard />
      ) : (
        <DataCard
          accordion={false}
          metric={formatNumberWithCommas(
            conversationMetrics.matching_conversations.toString(),
          )}
          title="Total Conversations"
          className={view === VIEW_OPTIONS.AI_DEBUGGER ? 'tall' : ''}
          visual={
            view === VIEW_OPTIONS.AI_DEBUGGER ? (
              <StyledJsonButton
                className={
                  conversationMetrics.matching_conversations <
                  conversationMetrics.total_conversations
                    ? 'drop'
                    : ''
                }
                onClick={() => triggerJSONDownload()}
                size="small"
                variant="secondary"
                monochrome
              >
                Download JSON
              </StyledJsonButton>
            ) : (
              <Icon component={IconSet.Message} size={30} />
            )
          }
          periodLabel={
            conversationMetrics.matching_conversations <
            conversationMetrics.total_conversations
              ? `${Math.round(
                  conversationMetrics.total_conversations
                    ? (conversationMetrics.matching_conversations /
                        conversationMetrics.total_conversations) *
                        100
                    : 0,
                ).toFixed(2)}% of ${formatNumberWithCommas(
                  conversationMetrics.total_conversations.toString(),
                )}`
              : undefined
          }
        />
      )}
      {areSubscriberMetricsLoading ? (
        <StyledSkeletonCard />
      ) : (
        <DataCard
          accordion={false}
          metric={formatNumberWithCommas(
            subsrciberMetrics.matching_subscribers.toString(),
          )}
          title="Unique Subscribers"
          visual={<Icon component={IconSet.Users} size={30} />}
          periodLabel={
            subsrciberMetrics.matching_subscribers ===
            subsrciberMetrics.total_subscribers
              ? ''
              : `${Math.round(
                  (100 * subsrciberMetrics.matching_subscribers) /
                    subsrciberMetrics.total_subscribers,
                ).toFixed(2)}% of ${formatNumberWithCommas(
                  subsrciberMetrics.total_subscribers.toString(),
                )} ${selectedDateRangeLabel()}`
          }
        />
      )}
    </DataCardLayout>
  );

  const conversationsListTitleContent = areMetricsVisible ? (
    <StyledCardHeadingRow>
      <StyledCardHeading size="x-small">
        Highlights and summaries
      </StyledCardHeading>
      <Switch
        checked={!controls.filters.includes(HAS_SUMMARY)}
        onChange={toggleHasSummaryFilter}
        fieldSize="small"
        id="unsummarized-toggle"
        label="Show unsummarized"
      />
    </StyledCardHeadingRow>
  ) : (
    <StyledSkeletonCard style={{ height: 36 }} />
  );

  const trendChartContent = isTopicLabelTrendsLoading ? (
    <StyledSkeletonCard style={{ height: 360 }} />
  ) : (
    <StyledChart>
      <Bar data={chartData} height={360} options={chartOptions} />
    </StyledChart>
  );

  const searchAndFilterBar = (title: any) => {
    return (
      <>
        <StyledTitleContainer>
          <StyledSearchHeader
            filtersSelected={filtersSelected}
            onClick={() => setSearchControlsAreOpen(!searchControlsAreOpen)}
          >
            <StyledSearchHeaderTitle>
              <Icon
                color="var(--text-color-dim)"
                component={IconSet.Search}
                size={24}
              />
              <StyledSearchText
                forwardedAs="h2"
                color="var(--text-color-dim)"
                size="xx-small"
              >
                {title}
              </StyledSearchText>
              <StyledSearchButtons>
                <Button
                  icon={IconSet.Filter}
                  iconPosition="right"
                  monochrome
                  onClick={() =>
                    setSearchControlsAreOpen(!searchControlsAreOpen)
                  }
                  size="small"
                  variant="text"
                >
                  {filtersSelected ? 'Edit Filters' : 'Search and Filter'}
                </Button>
              </StyledSearchButtons>
            </StyledSearchHeaderTitle>
            <StyledSearchHeaderLogline>
              {badgeElements('page')}
            </StyledSearchHeaderLogline>
          </StyledSearchHeader>

          <ConversationIntelligenceControls
            badgeElements={badgeElements('modal')}
            controls={controls}
            fullClearFunction={setControls}
            onControlsUpdate={(newControls) => {
              setControls({ ...controls, ...newControls });
            }}
            isLoading={isLoading}
            isMultiShop={isMultiShop}
            isOpen={searchControlsAreOpen}
            toggleFunction={setSearchControlsAreOpen}
          />
        </StyledTitleContainer>
      </>
    );
  };

  return (
    <>
      {!isMultiShop && (
        <ViewSelectorWrapper>
          <ViewSelector />
        </ViewSelectorWrapper>
      )}

      <PageHeader
        pageTitle={
          <>
            Conversation Intelligence
            <StyledBetaBadge size="small" variant="success">
              Beta
            </StyledBetaBadge>
          </>
        }
        sticky={false}
        actions={!noConversations && dateRangeTabGroup()}
      />

      {noConversations ? (
        <StyledZeroState>
          <StyledZeroStateText>
            <Heading as="h2" size="small">
              Your conversations will appear here soon
            </Heading>
            <BodyText>
              Once SMS Sales is active, you&apos;ll see your conversations here
              for review. After 1,000 conversations, we&apos;ll start generating
              topics, sentiments, and other insights to help you understand your
              interactions better.
            </BodyText>
          </StyledZeroStateText>
          <StyledZeroStateCards>
            <StyledZeroStateCard>
              <Icon
                color="var(--color-topic-primary)"
                component={IconSet.TwoWayConvo}
                size={48}
              />
              <Heading size="x-small">Review conversations</Heading>
              <BodyText size="small">
                Analyze interactions to uncover trends and refine your
                messaging.
              </BodyText>
            </StyledZeroStateCard>
            <StyledZeroStateCard>
              <Icon
                color="var(--color-topic-primary)"
                component={IconSet.PostscriptAi}
                size={48}
              />
              <Heading size="x-small">Get insights</Heading>
              <BodyText size="small">
                Unlock AI-driven topics to improve sales and personalize offers.
              </BodyText>
            </StyledZeroStateCard>
            <StyledZeroStateCard>
              <Icon
                color="var(--color-topic-primary)"
                component={IconSet.Users}
                size={48}
              />
              <Heading size="x-small">Build better segments</Heading>
              <BodyText size="small">
                Leverage zero-party data to understand and categorize customers
                for precise targeting.
              </BodyText>
            </StyledZeroStateCard>
          </StyledZeroStateCards>
        </StyledZeroState>
      ) : (
        <StyledCIColumns className={isBubAI() ? 'multi-col' : 'single-col'}>
          {isConversationsLoading || !summaries ? (
            <MainColumn vertical>
              {noInsightsReady && showNoInsightsBanner && noInsightsBanner}
              <TitleAndChart>
                {searchAndFilterBar(titleText || 'Loading conversations')}
                {trendChartContent}
                {metricsContent}
              </TitleAndChart>

              <SummaryCardsAndTitle>
                {conversationsListTitleContent}

                {isLoading ? (
                  <>
                    <StyledSkeletonCardTall />
                    <StyledSkeletonCardTall />
                    <StyledSkeletonCardTall />
                  </>
                ) : (
                  <StyledVoidCard>
                    <EmptyState
                      size="medium"
                      heading="No conversations available"
                      description="Please refine your filters or return to the default view"
                    />
                  </StyledVoidCard>
                )}
              </SummaryCardsAndTitle>
            </MainColumn>
          ) : (
            <MainColumn vertical>
              {noInsightsReady && showNoInsightsBanner && noInsightsBanner}
              <TitleAndChart>
                {searchAndFilterBar(titleText)}
                {trendChartContent}
                {metricsContent}
              </TitleAndChart>

              <SummaryCardsAndTitle>
                {conversationsListTitleContent}

                {summaries.conversations.length > 0 ? (
                  summaries.conversations.map(renderConversationCard)
                ) : (
                  <StyledVoidCard>
                    <EmptyState
                      size="medium"
                      heading="No conversations available"
                      description="Please refine your filters or return to the default view"
                    />
                  </StyledVoidCard>
                )}

                <StyledPaginationWrapper>
                  {renderPaginationControls()}
                </StyledPaginationWrapper>
              </SummaryCardsAndTitle>
            </MainColumn>
          )}
          {isBubAI() && (
            <RightColumn>
              {isTopTagsLoading || !topTagsToDisplay ? (
                <StyledSkeletonHashtagCard />
              ) : (
                <StyledHashtagCard>
                  <StyledHashtagHeader>
                    <Icon size="large" component={IconSet.Bulb} />
                    <Heading size="xx-small">Insights</Heading>
                  </StyledHashtagHeader>
                  <StyledTabGroup
                    id="ci-tags-and-topics"
                    description="View tags and topics"
                  >
                    <button
                      data-selected={selectedTabGroup === 'topics'}
                      data-tabpanel={TOPICS_TAB_PANEL_ID}
                      onClick={() => setSelectedTabGroup('topics')}
                      type="button"
                    >
                      Topics
                    </button>
                    <button
                      data-selected={selectedTabGroup === 'tags'}
                      data-tabpanel={TAGS_TAB_PANEL_ID}
                      onClick={() => setSelectedTabGroup('tags')}
                      type="button"
                    >
                      Tags
                    </button>
                  </StyledTabGroup>
                  <StyledHashtagContainer>
                    {selectedTabGroup === 'topics' &&
                      topTopicsToDisplay.map((category, index) => {
                        const isFirstCategory = index === 0;
                        return (
                          <StyledTopicCategoryGroup>
                            <TopicCategoryTitle>
                              <BodyText size="x-small">
                                <strong>{category.name}</strong>
                              </BodyText>
                              {isFirstCategory && (
                                <Layout
                                  gap="var(--spacing-2)"
                                  style={{ paddingRight: '3px' }}
                                >
                                  <Icon
                                    color="var(--text-color-dim)"
                                    size={18}
                                    component={IconSet.Percentage}
                                  />
                                  <Icon
                                    color="var(--text-color-dim)"
                                    size={18}
                                    component={IconSet.EmojiHappy}
                                  />
                                </Layout>
                              )}
                            </TopicCategoryTitle>
                            {category.topics.map((topic) => {
                              const selected =
                                topic.label === controls.topicLabel;

                              const percentage = formatPercentage(
                                topic.total,
                                conversationMetrics.total_conversations,
                              );

                              const positivePercentage = formatPercentage(
                                topic.sentiments.positive,
                                topic.total,
                              );
                              const neutralPercentage = formatPercentage(
                                topic.sentiments.neutral,
                                topic.total,
                              );
                              const negativePercentage = formatPercentage(
                                topic.sentiments.negative,
                                topic.total,
                              );

                              return (
                                <StyledHashtagLineItem
                                  highlighted={selected}
                                  key={topic.label}
                                >
                                  <StyledTopic
                                    tabIndex={0}
                                    onClick={() =>
                                      setControls({
                                        ...controls,
                                        topicLabel: topic.label,
                                      })
                                    }
                                    role="button"
                                    href="#"
                                    highlighted={selected}
                                  >
                                    <StyledTopicIcon
                                      component={IconSet.Hashtag}
                                      color={
                                        selected
                                          ? 'var(--white)'
                                          : 'var(--blue-5)'
                                      }
                                    />

                                    {formatTopicLabel(topic.label)}
                                  </StyledTopic>
                                  <div>
                                    <StyledFreqSent
                                      aria-describedby={`tooltip-${topic.label}`}
                                      data-event={null}
                                      data-for={`tooltip-${topic.label}`}
                                      data-tip
                                      tabIndex={0}
                                    >
                                      <BodyText
                                        size="x-small"
                                        color={
                                          selected
                                            ? 'var(--white)'
                                            : 'var(--text-color-dim)'
                                        }
                                      >{`${percentage}%`}</BodyText>
                                      <SentimentBar
                                        negative={topic.sentiments.negative}
                                        neutral={topic.sentiments.neutral}
                                        positive={topic.sentiments.positive}
                                      />
                                    </StyledFreqSent>
                                    <Tooltip
                                      id={`tooltip-${topic.label}`}
                                      place="bottom"
                                    >
                                      <BodyText
                                        color="var(--color-text-tooltip)"
                                        bold
                                        size="x-small"
                                      >
                                        Frequency
                                      </BodyText>
                                      <BodyText
                                        color="var(--color-text-tooltip)"
                                        size="x-small"
                                      >
                                        {percentage}% · {topic.total}{' '}
                                        conversations
                                        <br />
                                        in the last {numDays} days
                                      </BodyText>

                                      <BodyText
                                        color="var(--color-text-tooltip)"
                                        bold
                                        size="x-small"
                                        style={{
                                          marginTop: 'var(--spacing-1)',
                                          marginBottom: 'var(--spacing-1)',
                                        }}
                                      >
                                        Sentiment
                                      </BodyText>
                                      <SentimentList>
                                        <SentimentItem>
                                          <IconWrapper>
                                            <Circle color="var(--color-sentiment-icon-negative)">
                                              <Icon
                                                color="var(--black)"
                                                size={14}
                                                component={IconSet.FaceSad}
                                              />
                                            </Circle>
                                          </IconWrapper>
                                          <BodyText
                                            color="var(--color-text-tooltip)"
                                            size="x-small"
                                          >
                                            {negativePercentage}% negative
                                          </BodyText>
                                        </SentimentItem>
                                        <SentimentItem>
                                          <IconWrapper>
                                            <Circle color="var(--color-sentiment-icon-neutral)">
                                              <Icon
                                                color="var(--black)"
                                                size={14}
                                                component={IconSet.FaceStraight}
                                              />
                                            </Circle>
                                          </IconWrapper>
                                          <BodyText
                                            color="var(--color-text-tooltip)"
                                            size="x-small"
                                          >
                                            {neutralPercentage}% neutral
                                          </BodyText>
                                        </SentimentItem>
                                        <SentimentItem>
                                          <IconWrapper>
                                            <Circle color="var(--color-sentiment-icon-positive)">
                                              <Icon
                                                color="var(--black)"
                                                size={14}
                                                component={IconSet.FaceSmile}
                                              />
                                            </Circle>
                                          </IconWrapper>
                                          <BodyText
                                            color="var(--color-text-tooltip)"
                                            size="x-small"
                                          >
                                            {positivePercentage}% positive
                                          </BodyText>
                                        </SentimentItem>
                                      </SentimentList>
                                    </Tooltip>
                                  </div>
                                </StyledHashtagLineItem>
                              );
                            })}
                          </StyledTopicCategoryGroup>
                        );
                      })}
                    {selectedTabGroup === 'topics' &&
                      (!topTopicsToDisplay || topTopicsToDisplay.length <= 0) &&
                      (noInsightsReady ? (
                        <EmptyState
                          description="Topic insights begin generating after 500-1000 conversations"
                          size="small"
                        />
                      ) : (
                        <EmptyState
                          description="No topics available for this time period"
                          size="small"
                        />
                      ))}
                    {selectedTabGroup === 'tags' && (
                      <>
                        {(isTagsExpanded
                          ? topTagsToDisplay.slice(0, MAX_TAGS_TO_DISPLAY)
                          : topTagsToDisplay.slice(0, 10)
                        ).map((tagLine) => {
                          const selected = tagLine.tag === controls.tag;
                          return (
                            <StyledHashtagLineItem
                              highlighted={selected}
                              key={tagLine.tag}
                            >
                              <StyledTopic
                                tabIndex={0}
                                onClick={() =>
                                  setControls({
                                    ...controls,
                                    tag: tagLine.tag,
                                  })
                                }
                                role="button"
                                href="#"
                                highlighted={selected}
                              >
                                <StyledTopicIcon
                                  component={IconSet.Tag}
                                  color={
                                    selected ? 'var(--white)' : 'var(--blue-5)'
                                  }
                                />

                                {formatTopicLabel(tagLine.tag)}
                              </StyledTopic>
                              <StyledTopicPercentage
                                highlighted={selected}
                                aria-describedby={`tooltip-${tagLine.tag}`}
                                data-event={null}
                                data-for={`tooltip-${tagLine.tag}`}
                                data-tip
                                tabIndex={0}
                              >{`${tagLine.percentage.toFixed(
                                2,
                              )}%`}</StyledTopicPercentage>
                              <Tooltip
                                id={`tooltip-${tagLine.tag}`}
                                place="bottom"
                              >
                                <BodyText
                                  color="var(--color-text-tooltip)"
                                  size="x-small"
                                >
                                  {`Tag associated with ${tagLine.percentage.toFixed(
                                    2,
                                  )}% of conversations in this time period.`}
                                </BodyText>
                              </Tooltip>
                            </StyledHashtagLineItem>
                          );
                        })}
                        {topTagsToDisplay.length > 10 && (
                          <ViewMoreTagsWrapper>
                            <ViewMoreHr />
                            <ViewMoreButtonWrapper>
                              <Button
                                onClick={() =>
                                  setIsTagsExpanded(!isTagsExpanded)
                                }
                                iconPosition="right"
                                icon={
                                  isTagsExpanded
                                    ? IconSet.ChevronUp
                                    : IconSet.ChevronDown
                                }
                                size="small"
                                variant="text"
                                monochrome
                              >
                                {isTagsExpanded
                                  ? 'View Less'
                                  : `View ${Math.min(
                                      topTagsToDisplay.length - 10,
                                      MAX_TAGS_TO_DISPLAY - 10,
                                    )} More`}
                              </Button>
                            </ViewMoreButtonWrapper>
                          </ViewMoreTagsWrapper>
                        )}
                        {!topTagsToDisplay ||
                          (topTagsToDisplay.length <= 0 &&
                            (noInsightsReady ? (
                              <EmptyState
                                description="Tags will appear 48 hours after each conversation ends"
                                size="small"
                              />
                            ) : (
                              <EmptyState
                                description="No tags available for this time period"
                                size="small"
                              />
                            )))}
                      </>
                    )}
                  </StyledHashtagContainer>
                </StyledHashtagCard>
              )}
            </RightColumn>
          )}
        </StyledCIColumns>
      )}

      <ChatContainer isOpen={!!selectedCardId} ref={chatDrawerRef}>
        <ChatCard>
          <ChatHeader>
            {subscriber && (
              <ChatSummary>
                {isMultiShop && conversation && (
                  <Badge
                    style={{
                      backgroundColor: salesShopsData
                        ? salesShopsData.shop_data.find(
                            (shop) => shop.id === conversation.shop?.id,
                          )?.sales_shop_color
                        : undefined,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflowX: 'hidden',
                      color: salesShopsData
                        ? salesShopsData.shop_data.find(
                            (shop) => shop.id === conversation.shop?.id,
                          )?.sales_shop_text_color
                        : undefined,
                    }}
                  >
                    {conversation.shop?.name || 'Unknown Shop'}
                  </Badge>
                )}
                <StyledSummaryHeader>
                  {identifier && <Heading size="x-small">{identifier}</Heading>}
                  {conversation && getLogline(conversation)}
                </StyledSummaryHeader>
              </ChatSummary>
            )}
            <Button
              icon={IconSet.Close}
              monochrome
              size="large"
              variant="text"
              onClick={clearConversation}
              style={{ marginLeft: 'auto' }}
            />
          </ChatHeader>
          {subscriber ? (
            <>
              <StyledChatSubContainer>
                <MessageThread
                  subscriber={subscriber}
                  messageThreadRef={messageThreadRef}
                />
              </StyledChatSubContainer>

              <ChatActions>
                <Button
                  onClick={() => copyConversationLinkToClipboard()}
                  icon={IconSet.Link}
                  size="small"
                  variant="secondary"
                >
                  Copy Link
                </Button>
                <Button
                  onClick={() => copyConversationToClipboard()}
                  icon={IconSet.Text}
                  size="small"
                  variant="secondary"
                >
                  Copy Conversation
                </Button>
              </ChatActions>
            </>
          ) : (
            <ChatEmpty>
              <EmptyState size="medium" description="Loading conversation..." />
            </ChatEmpty>
          )}
        </ChatCard>
        <ChatBackdrop onClick={clearConversation} />
      </ChatContainer>
    </>
  );
};

export default ConversationIntelligence;
