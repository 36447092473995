import {
  Badge,
  BodyText,
  getTextStyles,
  Icon,
  IconSet,
  toast,
} from '@postscript/components';
import AccountStatusCTA from 'components/account/AccountStatusCTA';
import { PERMISSIONS } from 'components/account/AccountView/users/constants';
import {
  userHasLegacyRole,
  userHasPermission,
} from 'components/account/AccountView/users/helpers';
import {
  LegacyRoles,
  Permissions,
  UserProfile,
} from 'components/account/AccountView/users/types';
import { USAGE_BILLING_ENABLED } from 'components/admin/utils/feature-flags';
import { useUsageBilling } from 'components/billing/context/usageBilling';
import NavMenuSearch from 'components/navigation/NavMenuSearch';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import Helpers from 'controllers/helpers';
import { useTotalSubscribers } from 'hooks/useTotalSubscribers';
import NavLogo from 'img/NavLogo';
import React, { Fragment, useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { GlobalHotKeys } from 'react-hotkeys';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isSalesRoute } from 'utils/isSalesRoute';
import MediaQueries from 'utils/mediaQueries';
import AIAnnouncement from './AIAnnouncement';
import { logNavItemClicked } from './eventUtils';
import UsageBillingAnnouncement from './UsageBillingAnnouncement';

const getPageLink = (url: string) =>
  url.includes('?') ? url.substring(0, url.indexOf('?')) : url;

/* STYLES */

type NavMenuListSearch = {
  isSearchActive?: boolean;
};

/* Main Container */
const StyledNavMenuContainer = styled.nav<{
  isSearchActive?: boolean;
}>`
  padding: 0;
  min-height: calc(100vh - var(--spacing-6));
  height: calc(100vh - var(--spacing-6));
  flex: 1 0 var(--nav-menu-width);
  display: flex;
  flex-direction: column;
  position: sticky;
  top: var(--spacing-6);
  transform: translateX(0);
  transition: transform var(--nav-menu-transition-speed),
    flex var(--nav-menu-transition-speed),
    opacity var(--nav-menu-transition-speed);
  will-change: flex, transform;
  width: var(--nav-menu-width);
  opacity: 1;

  ${MediaQueries.desktopOnly} {
    .full-screen-editor:not(.sidebar-navigation-open) & {
      opacity: 0;
      pointer-events: none;
      min-width: 0;
      flex: 0 1 0;
    }
  }

  ${MediaQueries.tabletAndPhone} {
    position: absolute;
    left: calc(-1 * var(--nav-menu-width));
    top: var(--spacing-4);
    min-height: calc(100vh - var(--spacing-4));
    height: calc(100vh - var(--spacing-4));
  }

  ${MediaQueries.phoneOnly} {
    left: calc(-1 * var(--nav-menu-width));
    top: 0;
    min-height: 100vh;
    height: 100vh;
  }
`;

const StyledNavWrapper = styled.div<{ isSearchActive?: boolean }>`
  width: 100%;
  min-width: var(--nav-menu-width);
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 1;
  position: absolute;

  padding: var(--spacing-3) var(--spacing-4)
    ${(props) => (props.isSearchActive ? '0' : '66px')} var(--spacing-3);
  flex: 1 0 100%;

  ${MediaQueries.desktopOnly} {
    transform: translateX(0);
    transition: transform var(--nav-menu-transition-speed),
      opacity var(--nav-menu-transition-speed);

    .full-screen-editor:not(.sidebar-navigation-open) & {
      transform: translateX(-288px);
      opacity: 0;
      pointer-events: none;

      // prevent scrollbar from flashing on hide
      menu {
        overflow: hidden;
      }
    }
    .full-screen-editor:not(.sidebar-navigation-open) & {
      transform: translateX(-288px);
      opacity: 0;
      pointer-events: none;
    }
  }
`;

/* Logo Link */
const StyledLogoLink = styled(Link)`
  width: 135px;
  height: 42px;
  margin: 0 0 var(--spacing-1) var(--spacing-1);
  flex: 0 1 42px;
  outline: none !important;
  transform-origin: center;

  path {
    transition: fill var(--hover-transition);
  }

  /* handle coloring and transforms */

  --logo-bkg-color: var(--purple-core);
  --logo-text-color: var(--white);

  --logo-bkg-hover-color: var(--purple-7);
  --logo-bkg-active-color: var(--purple-8);

  &.sales-admin-view {
    --logo-bkg-color: var(--green-core);
    --logo-bkg-hover-color: var(--green-3);
    --logo-bkg-active-color: var(--green-4);
  }

  [data-theme='dark'] & {
    --logo-bkg-hover-color: var(--purple-5);
    --logo-bkg-active-color: var(--purple-4);

    &.sales-admin-view {
      --logo-bkg-color: var(--green-core);
      --logo-bkg-hover-color: var(--green-1);
      --logo-bkg-active-color: var(--white);
    }
  }

  &:hover {
    --logo-bkg-color: var(--logo-bkg-hover-color);
  }

  &:active {
    --logo-bkg-color: var(--logo-bkg-active-color);
  }

  &:not(:hover):focus {
    --logo-bkg-color: var(--logo-bkg-hover-color);
  }
`;

/* SubNav Menu -- Container */
const StyledSubnavMenuList = styled.menu`
  display: none;
  transition: display var(--nav-menu-transition-speed);
  padding: 0 0 0 23px; // -1 for border
  position: relative;
  z-index: 2;

  &.current {
    border-left: 1px solid var(--border-color-dim);
  }

  & > .filter-inline {
    width: 180px;
    min-width: 180px;
    margin-bottom: var(--spacing-2);
    cursor: pointer;
    position: relative;
    z-index: 999;

    > .filter-inline {
      width: 180px;
      min-width: 180px;
    }
  }

  .admin-shop-drop {
    margin: 0 !important;
    width: 100%;
    font: var(--body-text-x-small);

    .admin-shop-drop-inner__control:hover {
      border-color: var(--highlight-color);
    }

    div[class*='placeholder'] {
      position: unset !important;
      top: unset !important;
      transform: unset !important;
      line-height: 100%;
    }

    .admin-shop-drop-inner__placeholder + div {
      line-height: 100%;
    }
  }
`;

/* SubNav Menu -- Link Text */
const StyledSubnavButtonText = styled.span`
  display: inline-block;
`;

/* SubNav Menu -- Link */
const StyledSubnavButton = styled(Link)`
  position: relative;
  display: inline-flex;
  font: var(--body-text-small);
  color: var(--color-sidebar-nav-text);
  padding: 3px 0;
  border-radius: 15px;
  margin-left: var(--spacing-1);
  outline: none !important;

  /* NON-CURRENT */

  &:not(.current):hover {
    color: var(--color-sidebar-nav-text-hover);
    text-decoration: none;
  }

  &:not(.current):active {
    color: var(--color-sidebar-nav-text-active);
  }

  &:not(:hover):focus ${StyledSubnavButtonText} {
    text-decoration: underline;
  }

  &:not(.current):not(:active):focus {
    color: var(--color-sidebar-nav-text-focus);
  }
  &:not(.current):not(:active):focus ${StyledSubnavButtonText} {
    text-decoration: underline;
  }

  /* CURRENT */

  &.current {
    background: var(--color-sidebar-nav-text);
    font-weight: var(--body-text-bold-font-weight);
    padding: 3px 12px;
    color: var(--main-bkg-color);
    margin-left: 0;
  }
  &.current:before {
    opacity: 1;
  }
  &.current:hover {
    background: var(--color-sidebar-nav-text-hover);
    text-decoration: none;
  }
  &.current:active {
    background: var(--color-sidebar-nav-text-active);
  }
  &.current:not(:active):focus ${StyledSubnavButtonText} {
    text-decoration: underline;
  }
`;

/* SubNav Menu -- Item LI */
const StyledSubnavItemLi = styled.li`
  list-style: none;
  padding: 0;
  outline: none !important;

  // truncate line
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) ${StyledSubnavButton} {
    margin-bottom: var(--spacing-1);
  }
`;

interface AvatarDisplayProps {
  $shouldShowProfile?: boolean;
  shouldShowAvatar?: boolean;
}

/* Main Menu -- Icon Container */
const StyledNavButtonIconContainer = styled.div<AvatarDisplayProps>`
  display: block;
  height: var(--spacing-5);
  width: var(--spacing-5);
  padding: 3px;
  position: relative;
  z-index: 1;
  cursor: pointer;
`;

/* Main Menu -- Icon */
const StyledNavButtonIcon = styled(Icon)`
  color: inherit;
  position: relative;
  z-index: 2;
  transition: color var(--hover-transition);
`;

/* Main Menu -- Account Logo */
const StyledAccountLogo = styled.img`
  position: relative;
  z-index: 2;
  border-radius: var(--border-radius-round);
`;

/* Main Menu -- Profile Avatar */
const StyledAvatar = styled.div`
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 24px;
  height: 24px;
  z-index: 2;
  border-radius: var(--border-radius-round);

  .current & {
    box-shadow: 0 0 0 1px var(--main-bkg-color),
      0 0 0 3px var(--highlight-color);
  }
`;

/* Main Menu -- Button Text */
const StyledNavButtonTextLabel = styled.span`
  display: flex;
  align-items: center;
`;

const StyledNavButtonText = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 0;
  transition: background-color var(--hover-transition),
    color var(--hover-transition), padding var(--hover-transition);
  position: relative;
  padding: 3px 0;
  z-index: 1;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

/* Main Menu -- Button */
const StyledNavButton = styled(Link)<AvatarDisplayProps>`
  ${getTextStyles.bodyText('medium', { isBold: true })};
  display: flex;
  gap: var(--spacing-1);
  position: relative;
  height: var(--spacing-5);
  color: var(--color-sidebar-nav-text);
  text-decoration: none;
  transition: gap var(--hover-transition);

  ${({ $shouldShowProfile }) =>
    $shouldShowProfile && 'height: var(--spacing-8);'}

  /* HOVER STATE */
  &:not(.current):hover {
    color: var(--color-sidebar-nav-text-hover);
    text-decoration: none;
  }

  &:not(.current):hover ${StyledNavButtonIcon} {
    color: var(--color-sidebar-nav-text-hover);
  }

  /* ACTIVE STATE */
  &:not(.current):active {
    color: var(--color-sidebar-nav-text-active);
    text-decoration: none;
  }

  &:not(.current):active ${StyledNavButtonIcon} {
    color: var(--color-sidebar-nav-text-active);
  }

  /* FOCUS STATE */
  &:focus {
    outline: none;
  }
  &:not(.current):not(:active):focus {
    color: var(--color-sidebar-nav-text-focus);
    text-decoration: underline;
  }

  &:not(.current):not(:active):focus ${StyledNavButtonIcon} {
    color: var(--color-sidebar-nav-text-focus);
  }

  /* CURRENT STATES */

  &.lone {
    ${StyledNavButtonText} {
      border-radius: var(--border-radius-round);
    }

    &.current {
      gap: 0;

      ${StyledNavButtonText} {
        background: var(--color-sidebar-nav-text);
        color: var(--main-bkg-color);
        padding: 3px 12px;
      }

      &:hover ${StyledNavButtonText} {
        background: var(--color-sidebar-nav-text-hover);
      }
    }
  }

  &.current:hover {
    color: var(--color-sidebar-nav-text-hover);
    text-decoration: none;
  }

  &.current:active {
    color: var(--color-sidebar-nav-text-active);
  }

  &.current:not(:active):focus {
    color: var(--color-sidebar-nav-text-focus);
    text-decoration: underline;
  }

  &.current .notification-badge {
    display: none;
  }
`;

/* Main Menu -- Item LI */
const StyledNavItemLi = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
  justify-content: center;
  max-width: 100%;

  &.current + ${StyledSubnavMenuList} {
    display: block;
    margin: 3px 0 var(--spacing-2) 15px; // half 30px circle
  }
`;

/* Main Menu -- Container */
const StyledNavMenuList = styled.menu<NavMenuListSearch>(
  ({ isSearchActive }) => `
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    list-style: none;
    padding: var(--spacing-6) 0 0;
    margin: 0;
    flex: 1 0 calc(100% - 84px);
    height: calc(100% - 84px);
    overflow-y: auto;
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-scrollbar-width: none;
    scrollbar-width: none;

  ${
    isSearchActive &&
    `
    border: none !important;
    overflow-y: hidden;
  `
  }
`,
);

const StyledCreditItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: calc((-1 * var(--spacing-2)) - 3px);
`;

/* Lower Links */
const StyledLinkage = styled.footer<{ direction?: 'horizontal' | 'vertical' }>`
  background: var(--main-bkg-color);
  flex: 0 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--text-color-dim);
  font: var(--body-text-x-small);
  line-height: 150%;
  height: 48px;
  width: calc(100% - var(--spacing-7));
  border-top: 1px solid var(--border-color-dim);
  position: absolute;
  padding-top: var(--spacing-2);
  bottom: var(--spacing-3);
  left: var(--spacing-3);
  z-index: 2;

  & a {
    color: var(--text-color-dim);
    padding: 0 var(--spacing-1);
    outline: none !important;
  }
  & a:hover {
    color: var(--link-color);
    text-decoration: none;
  }
  & a:active {
    color: var(--link-color-hover);
    text-decoration: none;
  }
  & a:not(:hover):focus {
    color: var(--link-color);
    text-decoration: underline;
  }
`;

const TagBadge = styled(Badge)`
  margin-left: var(--spacing-1);
  align-self: center;
  text-decoration: none !important;
`;

const UpdatesBadge = styled(Badge)`
  line-height: 18px;
  padding: calc(var(--spacing-1) / 2) var(--spacing-1);
`;

const CreditsProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  padding-top: var(--spacing-1);
  max-width: calc(var(--nav-menu-width) - var(--spacing-1));
`;

const CreditsProgressLink = styled(Link)`
  color: var(--text-color-dim);
  font: var(--body-text-x-small);
  outline: none !important;
  display: flex;
  flex-direction: column;

  #credits-remaining-arrow {
    margin: 2px 0 0 3px;
    transition: transform var(--hover-transition);
  }

  &:hover {
    color: var(--link-color);
    text-decoration: none;

    #credits-remaining-arrow {
      transform: translate(2px);
    }
  }

  &:active {
    color: var(--link-color-hover);
    text-decoration: none;
  }

  &:not(:hover):focus {
    color: var(--link-color);
    text-decoration: underline;

    #credits-remaining-arrow {
      transform: translate(2px);
    }
  }
`;

const CreditsProgressBackground = styled.div<{ color: string }>(
  ({ color }) => `
  width: calc(10 * var(--spacing-2));
  height: var(--spacing-1);
  border-radius: calc(10 * var(--spacing-2));
  background-color: ${color};
`,
);

const CreditsProgressBar = styled.div<{ percentage: number; color: string }>(
  ({ percentage, color }) => `
  margin-top: calc(-1 * var(--spacing-1));
  margin-bottom: var(--spacing-1);
  width: calc((10 * var(--spacing-2)) * ${percentage});
  min-width: ${percentage > 0 ? `var(--spacing-1)` : `6px`};
  height: var(--spacing-1);
  border-radius: calc(10 * var(--spacing-2));
  background-color: ${color};
`,
);

const CreditsNavItem = styled.div`
  padding-left: var(--spacing-6);

  &:empty {
    display: none;
  }
`;

const PENDO_ANIMATION = 'pendo-resource-center-bubble-animation';
const PENDO_BUBBLE = 'pendo-resource-center-badge-notification-bubble';

const HideUpdatesBell = styled.span`
  .fa-bell::before {
    display: none;
  }

  .fa-bell,
  .text-light {
    color: inherit !important;
    font: inherit !important;
  }

  a:hover > .fa-bell {
    color: var(--link-color) !important;
  }

  a:focus > .fa-bell,
  a:active > .fa-bell {
    color: var(--link-color) !important;
    text-decoration: underline !important;
    text-decoration-color: var(--link-color) !important;
  }

  #${PENDO_ANIMATION}, .${PENDO_BUBBLE} {
    display: none;
  }
`;

const UnderlineText = styled.span`
  text-decoration: underline;
`;

const CreditRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CreditsText = styled.div`
  display: flex;
  justify-content: center;
  font-weight: var(--body-text-bold-font-weight);
`;

/* BUILDS */

/* Main Menu Item */
const NavMenuItem = ({
  current = true,
  icon,
  label,
  tag,
  labelledBy,
  id,
  link,
  setActiveItem,
  accountLogoURL,
  userProfile,
  notification: Notification,
}: {
  current?: boolean;
  icon: () => JSX.Element;
  label: string;
  tag?: string;
  labelledBy?: string;
  id: string;
  link?: string;
  setActiveItem?: (event: any) => void;
  accountLogoURL?: string;
  userProfile?: UserProfile;
  notification?: React.ComponentType;
}): JSX.Element => {
  const shouldShowProfile =
    // Nav item is account
    id === 'account' &&
    // We have a name to show
    !!userProfile?.firstName;

  const name =
    shouldShowProfile && `${userProfile.firstName} ${userProfile.lastName}`;

  const StyledNavButtonClassName = `${current ? 'current' : ''} ${
    label === 'Dashboard' ? 'lone' : ''
  }`;

  return (
    <StyledNavItemLi className={current ? 'current' : ''}>
      <StyledNavButton
        className={StyledNavButtonClassName}
        aria-labelledby={labelledBy}
        tabIndex={0}
        to={current ? '#' : link ?? '#'}
        onClick={setActiveItem}
        $shouldShowProfile={shouldShowProfile}
      >
        <StyledNavButtonIconContainer
          shouldShowAvatar={!!(shouldShowProfile && userProfile?.avatarUrl)}
        >
          {(() => {
            if (shouldShowProfile && userProfile?.avatarUrl) {
              return (
                <StyledAvatar
                  style={{
                    backgroundImage: `url(${userProfile.avatarUrl})`,
                  }}
                />
              );
            }

            if (id === 'account' && accountLogoURL) {
              return (
                <StyledAccountLogo
                  src={accountLogoURL}
                  width={24}
                  height={24}
                  alt="account logo"
                />
              );
            }

            return <StyledNavButtonIcon size={24} component={icon} />;
          })()}
        </StyledNavButtonIconContainer>

        <StyledNavButtonText>
          <StyledNavButtonTextLabel>
            {label}
            {tag && <TagBadge variant="success">{tag}</TagBadge>}
          </StyledNavButtonTextLabel>

          {name && <BodyText size="x-small">{name}</BodyText>}
        </StyledNavButtonText>
        {Notification && <Notification />}
      </StyledNavButton>
    </StyledNavItemLi>
  );
};

/* Subnav Menu Item */
const SubnavMenuItem = ({
  label,
  link,
  dataId,
  tag,
  notification: Notification,
  current = false,
  onClick,
}: {
  label: string;
  link: string;
  dataId: string;
  tag?: string;
  notification?: React.ComponentType<{ isActive?: boolean }>;
  current?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <StyledSubnavItemLi>
      <StyledSubnavButton
        className={current ? 'current' : ''}
        to={onClick ? '#' : link}
        onClick={(event) => {
          logNavItemClicked(event);
          if (onClick) onClick();
        }}
        tabIndex={0}
        data-cy={dataId}
      >
        <StyledSubnavButtonText>{label}</StyledSubnavButtonText>
        {tag && <TagBadge variant="success">{tag}</TagBadge>}
        {Notification && <Notification isActive={current} />}
      </StyledSubnavButton>
    </StyledSubnavItemLi>
  );
};
const getLink = (item: string | (() => string)) =>
  typeof item === 'function' ? item() : item;

type SubMenuItem = {
  link: string | (() => string);
  label: string;
  id: string;
  tag?: string;
  notification?: React.ComponentType<{ isActive?: boolean }>;
  featureFlag?: string;
  featureFlagOptOut?: boolean;
  labsFeatureFlag?: string;
  gatedFeatureKey?: string;
  adminOnly?: boolean;
  permission?: Permissions;
  legacyRole?: LegacyRoles;
  restrictions?: Permissions[];
};

export type NavMenuItem = {
  label: string;
  icon: () => JSX.Element;
  tag?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  subMenu?: SubMenuItem[];
  id: string;
  featureFlag?: string;
  featureFlagOptOut?: boolean;
  labsFeatureFlag?: string;
  link?: string;
  notification?: React.ComponentType<{ isActive?: boolean }>;
  restrictions?: Permissions[];
};

const FOCUS_MODE_KEY = 'ps-nav-focus-mode';
const PENDO_BUBBLE_NUMBER = 'pendo-notification-bubble-unread-count';

const keyMap = {
  COPY_SWRO_URL: ['command+.', 'ctrl+.'],
  SEARCH: ['command+k', 'ctrl+k'],
  SELECT_SHOP: ['command+/', 'ctrl+/'],
};

/*
  Try up to 3 times to grab the pendo update number since
  Pendo injects its components after the DOM renders the first time
*/
const getUpdatesFromPendo = async (setUpdates: any, retries?: number) => {
  if (!retries || retries < 3) {
    const pendoBubbleNumber =
      document.getElementsByClassName(PENDO_BUBBLE_NUMBER)?.[0];

    if (pendoBubbleNumber) {
      const numUpdates = Number(pendoBubbleNumber.textContent);
      if (numUpdates) setUpdates(numUpdates);
    } else {
      setTimeout(() => {
        getUpdatesFromPendo(setUpdates, (retries ?? 0) + 1);
      }, 150);
    }
  }
};

/* Full Menu */
const NavMenu = ({
  menuConfig,
  logOut,
  isFullScreenEditor,
  isSidebarNavigationOpen,
  setIsSidebarNavigationOpen,
  SelectShop,
  pageLink = '/',
  accountLogoURL,
  userProfile,
  user,
}: {
  menuConfig: NavMenuItem[];
  logOut: () => void;
  isFullScreenEditor: boolean;
  isSidebarNavigationOpen: boolean;
  setIsSidebarNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  SelectShop: JSX.Element;
  pageLink?: string;
  accountLogoURL?: string;
  userProfile?: UserProfile;
  user?: any;
}): JSX.Element => {
  const [activeItem, setActiveItem] = useState('');
  const [focusMode, setFocusMode] = useState(false);
  const [updates, setUpdates] = useState(0);
  const [totalPercentageUsed, setTotalPercentageUsed] = useState<number>();
  const [creditsRemaining, setCreditsRemaining] = useState<number>();
  const { hasFlag }: any = useFeatureFlags();
  const { hasInitialized: psLabsInitialized, hasLabsFlag } = usePSLabs();
  const { hasPackageFeature } = useUsageBilling();
  const isSearchActive = activeItem === 'search';
  const shouldShowTotalSubscribers = true;
  const { data: subscribersTotal } = useTotalSubscribers({
    enabled: shouldShowTotalSubscribers,
  });

  const changeFocusMode = () => {
    const newMode = !focusMode;
    setFocusMode(newMode);
    localStorage.setItem(FOCUS_MODE_KEY, `${newMode}`);
  };

  const goToSearch = () => {
    setActiveItem('search');
  };

  const closeSearch = () => {
    // TODO: this is causing nav items to
    // not be displayed when closing search
    setActiveItem('');
  };

  const goToSelectShop = () => {
    setActiveItem('account');
    const innerInput = document.getElementsByClassName(
      'admin-shop-drop-inner__input',
    );
    const inputElement = innerInput?.[0]?.children?.[0] as HTMLElement;
    if (inputElement) inputElement.focus();
  };

  const copySwitcherooUrlToClipboard = () => {
    if (!user?.shop_id) return;

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.append('swro', user.shop_id);
    navigator.clipboard.writeText(currentUrl.href);

    toast.success(`Switcheroo URL copied to clipboard!`);
  };

  const hotKeyHandlers = {
    COPY_SWRO_URL: copySwitcherooUrlToClipboard,
    SEARCH: goToSearch,
    SELECT_SHOP: goToSelectShop,
  };

  useEffect(() => {
    const savedFocusMode = localStorage.getItem(FOCUS_MODE_KEY);

    if (savedFocusMode) {
      if (savedFocusMode === 'true') setFocusMode(true);
      else setFocusMode(false);
    }

    let usage = 0;
    let percentageUsed = 0;
    let allowed = 0;
    if (user) {
      if (user.texts_sent_this_month) {
        usage = user.texts_sent_this_month;
      }
      if (user.plan && user.plan.monthly_texts_allowed) {
        allowed = user.plan.monthly_texts_allowed;
        percentageUsed = usage / allowed;
      }
      if (
        user.billing_period_record &&
        user.billing_period_record.number_of_texts
      ) {
        usage = user.billing_period_record.number_of_texts;
      }
      if (
        user.billing_period_record &&
        user.billing_period_record.number_of_texts_allowed
      ) {
        allowed = user.billing_period_record.number_of_texts_allowed;
        percentageUsed = usage / allowed;
      }
    }

    setTotalPercentageUsed(percentageUsed);
    setCreditsRemaining(Math.max(allowed - usage, 0));
  }, [user]);

  useEffect(() => {
    getUpdatesFromPendo(setUpdates);
  }, []);

  useEffect(() => {
    // Determine current active menu item by the current URL
    const newPageId =
      pageLink === '/dashboard'
        ? 'dashboard'
        : // Iterate through menu items and sub menu items to find matching link
          menuConfig.find((menuItem) =>
            menuItem?.subMenu?.some((subMenuItem) =>
              pageLink.startsWith(getPageLink(getLink(subMenuItem.link))),
            ),
          )?.id;

    if (newPageId) {
      setIsSidebarNavigationOpen(false);
      setActiveItem(newPageId);
    } else {
      setActiveItem('');
    }
  }, [pageLink, menuConfig, setIsSidebarNavigationOpen]);

  const changeActiveItem = (item: string) => {
    if (activeItem === item && activeItem !== 'dashboard') {
      setActiveItem('');
    } else {
      setActiveItem(item);
    }
  };

  const getNavLink = (item: NavMenuItem): string => {
    if (item.subMenu && item.subMenu.length > 0 && !isSidebarNavigationOpen) {
      if (getLink(item.subMenu[0].link).startsWith('$')) {
        return getLink(item.subMenu[1].link) ?? '#';
      }
      return getLink(item.subMenu[0].link);
    }
    if (item.link) {
      return item.link;
    }

    return '#';
  };

  const getCreditsColor = () => {
    if (totalPercentageUsed) {
      if (totalPercentageUsed >= 0.9) {
        return { background: 'var(--red-8)', fill: 'var(--red-core)' };
      }
      if (totalPercentageUsed >= 0.7) {
        return { background: 'var(--orange-6)', fill: 'var(--orange-core)' };
      }
    }
    return {
      background: 'var(--purple-2)',
      fill: 'var(--purple-core)',
    };
  };

  const getSpecialSubItem = (link: string): JSX.Element | undefined | null => {
    const isPaused = user.plan.name === 'Paused Store';
    const isTrial = user.free_trial.active;

    switch (link) {
      case '$selectShop':
        return SelectShop;
      case '$usage-billing-announcement':
        return <UsageBillingAnnouncement />;
      case '$ai-announcement':
        return <AIAnnouncement />;
      case '$credits':
        return !hasFlag(USAGE_BILLING_ENABLED) &&
          totalPercentageUsed !== undefined &&
          creditsRemaining !== undefined &&
          !userHasPermission(PERMISSIONS.limit_sms_sales_agent_access) ? (
          <CreditsProgressContainer>
            <CreditsProgressBackground
              color={
                !isTrial
                  ? getCreditsColor().background
                  : 'var(--border-color-dim)'
              }
            />
            {!isTrial && (
              <CreditsProgressBar
                percentage={totalPercentageUsed}
                color={getCreditsColor().fill}
              />
            )}
            <CreditsProgressLink
              to="/account/plan"
              onClick={(event) => logNavItemClicked(event)}
            >
              {creditsRemaining > 0 && !isTrial && (
                <CreditRowContainer>
                  {creditsRemaining.toLocaleString()}
                  {' Credits Remaining '}
                  {!isPaused && (
                    <Icon
                      component={IconSet.ArrowRight}
                      size={14}
                      id="credits-remaining-arrow"
                    />
                  )}
                </CreditRowContainer>
              )}
              <CreditRowContainer>
                {!isPaused && creditsRemaining === 0 && !isTrial && (
                  <CreditsText>
                    <span>
                      {'Out of Credits! • '}
                      <UnderlineText>Upgrade</UnderlineText>
                    </span>
                    <Icon
                      component={IconSet.ArrowRight}
                      size={14}
                      id="credits-remaining-arrow"
                    />
                  </CreditsText>
                )}
                {isPaused && !isTrial && (
                  <CreditsText>
                    <span>
                      {'Plan Paused • '}
                      <UnderlineText>Upgrade</UnderlineText>
                    </span>
                    <Icon
                      component={IconSet.ArrowRight}
                      size={14}
                      id="credits-remaining-arrow"
                    />
                  </CreditsText>
                )}
                {isTrial && (
                  <CreditsText>
                    <span>
                      {'Free Trial • '}
                      <UnderlineText>Upgrade</UnderlineText>
                    </span>
                    <Icon
                      component={IconSet.ArrowRight}
                      size={14}
                      id="credits-remaining-arrow"
                    />
                  </CreditsText>
                )}
              </CreditRowContainer>
            </CreditsProgressLink>
          </CreditsProgressContainer>
        ) : null;
      case '$subscribers':
        return (
          shouldShowTotalSubscribers && (
            <BodyText size="x-small">
              {subscribersTotal
                ? `${Helpers.commaMe(subscribersTotal)} total`
                : 'No'}{' '}
              subscribers
            </BodyText>
          )
        );
      case '$account-status':
        return <AccountStatusCTA />;
      default:
        return undefined;
    }
  };

  const getSpecialSubItemOnClick = (link: string): undefined | (() => void) => {
    switch (link) {
      case '$switchMode':
        return () => changeFocusMode();
      case '$logOut':
        return () => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Are you sure you want to log out?')) {
            logOut();
          }
        };
      default:
        return undefined;
    }
  };

  const navItemHasRestriction = (item: NavMenuItem): boolean => {
    if (item.restrictions) {
      return item.restrictions.some((restriction) =>
        userHasPermission(restriction),
      );
    }
    return false;
  };

  const isSearchVisible = (): boolean => {
    if (
      isSalesRoute() ||
      userHasPermission(PERMISSIONS.limit_sms_sales_agent_access)
    ) {
      return false;
    }
    return true;
  };

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={hotKeyHandlers}>
      <StyledNavMenuContainer
        className="sentry-unmask"
        aria-hidden={!isSidebarNavigationOpen && isFullScreenEditor}
        role="navigation"
        aria-label="main"
        id="navigation-menu-container"
        isSearchActive={isSearchActive}
      >
        <StyledNavWrapper isSearchActive={isSearchActive}>
          <StyledLogoLink
            to="/"
            aria-label="Postscript | Dashboard"
            tabIndex={0}
            onClick={(event) => logNavItemClicked(event, 'Home Logo')}
            className={isSalesRoute() ? 'sales-admin-view' : 'default-view'}
          >
            <NavLogo />
          </StyledLogoLink>
          <StyledNavMenuList
            className={
              focusMode || isSearchActive
                ? activeItem === ''
                  ? ''
                  : 'focusMode'
                : ''
            }
            isSearchActive={isSearchActive}
          >
            {isSearchVisible() && (
              <NavMenuItem
                aria-label="Search"
                icon={IconSet.Search}
                label={isSearchActive ? '' : 'Search'}
                current={isSearchActive}
                setActiveItem={(event) => {
                  logNavItemClicked(event);
                  changeActiveItem('search');
                }}
                id="search"
              />
            )}

            {isSearchActive ? (
              <NavMenuSearch closeSearch={closeSearch} />
            ) : (
              menuConfig.map((menuItem) => {
                if (
                  (menuItem.id !== 'admin' || user.is_admin) &&
                  !navItemHasRestriction(menuItem)
                ) {
                  if (menuItem.featureFlag || menuItem.labsFeatureFlag) {
                    let menuItemHasFlag;
                    if (menuItem.featureFlag) {
                      menuItemHasFlag = hasFlag(menuItem.featureFlag);
                    }
                    if (menuItem.labsFeatureFlag && psLabsInitialized) {
                      menuItemHasFlag = hasLabsFlag(menuItem.labsFeatureFlag);
                    }

                    if (menuItem.featureFlagOptOut && menuItemHasFlag)
                      return null;
                    if (!menuItem.featureFlagOptOut && !menuItemHasFlag)
                      return null;
                  }
                  const itemLabel =
                    user && user?.shop_name && menuItem.id === 'account'
                      ? user.shop_name
                      : menuItem.label;
                  return (
                    <Fragment key={menuItem.id}>
                      <NavMenuItem
                        aria-label={menuItem.ariaLabel}
                        aria-labelledby={menuItem.ariaLabelledBy}
                        icon={menuItem.icon}
                        label={itemLabel}
                        tag={menuItem.tag}
                        link={getNavLink(menuItem)}
                        current={activeItem === menuItem.id}
                        setActiveItem={(event) => {
                          logNavItemClicked(event);
                          changeActiveItem(menuItem.id);
                        }}
                        accountLogoURL={accountLogoURL}
                        userProfile={userProfile}
                        id={menuItem.id}
                        notification={menuItem.notification}
                      />
                      {menuItem?.subMenu && (
                        <StyledSubnavMenuList
                          className={
                            activeItem === menuItem.id ? 'current' : undefined
                          }
                        >
                          {menuItem.subMenu.map((subMenuItem) => {
                            const specialSubItem = getSpecialSubItem(
                              getLink(subMenuItem.link),
                            );
                            if (specialSubItem !== undefined)
                              return (
                                <Fragment
                                  key={
                                    subMenuItem.label +
                                    getLink(subMenuItem.link)
                                  }
                                >
                                  {specialSubItem}
                                </Fragment>
                              );

                            if (
                              subMenuItem.featureFlag ||
                              subMenuItem.labsFeatureFlag
                            ) {
                              let menuItemHasFlag;
                              if (subMenuItem.featureFlag) {
                                menuItemHasFlag = hasFlag(
                                  subMenuItem.featureFlag,
                                );
                              }
                              if (
                                subMenuItem.labsFeatureFlag &&
                                psLabsInitialized
                              ) {
                                menuItemHasFlag = hasLabsFlag(
                                  subMenuItem.labsFeatureFlag,
                                );
                              }

                              if (
                                subMenuItem.featureFlagOptOut &&
                                menuItemHasFlag
                              ) {
                                return null;
                              }

                              if (
                                !subMenuItem.featureFlagOptOut &&
                                !menuItemHasFlag
                              ) {
                                return null;
                              }
                            }

                            if (subMenuItem.gatedFeatureKey) {
                              const hasFeature = hasPackageFeature({
                                featureKey: subMenuItem.gatedFeatureKey,
                              });

                              if (!hasFeature) return null;
                            }

                            if (subMenuItem.adminOnly && !user.is_admin) {
                              return null;
                            }

                            if (subMenuItem.permission) {
                              const hasPermission = userHasPermission(
                                subMenuItem.permission,
                              );

                              if (!hasPermission) return null;
                            }

                            if (subMenuItem.legacyRole) {
                              if (!userHasLegacyRole(subMenuItem.legacyRole))
                                return null;
                            }

                            if (subMenuItem.restrictions) {
                              const hasRestriction =
                                subMenuItem.restrictions.some((restriction) =>
                                  userHasPermission(restriction),
                                );

                              if (hasRestriction) return null;
                            }

                            return (
                              <SubnavMenuItem
                                current={pageLink.startsWith(
                                  getPageLink(getLink(subMenuItem.link)),
                                )}
                                link={
                                  getLink(subMenuItem.link).startsWith('$')
                                    ? '#'
                                    : getLink(subMenuItem.link)
                                }
                                onClick={getSpecialSubItemOnClick(
                                  getLink(subMenuItem.link),
                                )}
                                label={subMenuItem.label}
                                key={
                                  subMenuItem.label + getLink(subMenuItem.link)
                                }
                                tag={subMenuItem.tag}
                                notification={subMenuItem.notification}
                                dataId={`sub-nav-item-${subMenuItem.id}`}
                              />
                            );
                          })}
                        </StyledSubnavMenuList>
                      )}
                      {menuItem.id === 'account' && (
                        <>
                          <StyledCreditItems>
                            <CreditsNavItem>
                              {getSpecialSubItem('$subscribers')}
                            </CreditsNavItem>
                            <CreditsNavItem>
                              {getSpecialSubItem('$credits')}
                            </CreditsNavItem>
                            <CreditsNavItem>
                              {getSpecialSubItem('$account-status')}
                            </CreditsNavItem>
                          </StyledCreditItems>
                          {getSpecialSubItem('$usage-billing-announcement')}
                          {getSpecialSubItem('$ai-announcement')}
                        </>
                      )}
                    </Fragment>
                  );
                }
                return null;
              })
            )}
          </StyledNavMenuList>
          {!isSearchActive && (
            <StyledLinkage>
              <div>
                <a
                  href="https://postscript.io/patents"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(event) => logNavItemClicked(event)}
                >
                  Patents
                </a>

                <span>&bull;</span>

                <a
                  href="https://www.postscript.io/sms-compliance/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(event) => logNavItemClicked(event)}
                >
                  Compliance
                </a>

                <span>&bull;</span>

                <Link
                  to="/referrals"
                  onClick={(event) => logNavItemClicked(event)}
                >
                  Referrals
                </Link>
              </div>
              <div>
                <a
                  href="https://help.postscript.io/hc/en-us"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(event) => logNavItemClicked(event)}
                >
                  Help Center
                </a>

                <span>&bull;</span>

                <a
                  href="https://www.postscript.io/subscriber-messaging-terms/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(event) => logNavItemClicked(event)}
                >
                  Terms
                </a>

                <span>&bull;</span>

                <HideUpdatesBell>
                  <Link
                    to="/"
                    className="text-light"
                    onClick={(event) => event.preventDefault()}
                  >
                    <FontAwesome
                      name="bell"
                      aria-hidden="false"
                      onClick={(event) => event.preventDefault()}
                    >
                      {updates > 0 ? (
                        <UpdatesBadge variant="success">{`${updates} Update${
                          updates > 1 ? 's' : ''
                        }!`}</UpdatesBadge>
                      ) : (
                        'Updates'
                      )}
                    </FontAwesome>
                  </Link>
                </HideUpdatesBell>
              </div>
            </StyledLinkage>
          )}
        </StyledNavWrapper>
      </StyledNavMenuContainer>
    </GlobalHotKeys>
  );
};

export default NavMenu;
