import { BodyText, Card, Heading, LinkText } from '@postscript/components';
import { SELF_MIGRATE_TO_USAGE_BILLING } from 'components/admin/utils/feature-flags';
import PageHeader from 'components/layout/PageHeader';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { logLinkClickEvent, PRODUCT_AREAS } from 'utils/events';
import MarketingPlansList from '../plans/MarketingPlansList';
import ExplainerVideo from './ExplainerVideo';

const StyledCard = styled(Card)`
  display: flex;
  background: var(--purple-9);
  box-shadow: none;
  color: var(--text-color);
  margin-bottom: var(--spacing-6);

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const MediaWrapper = styled.div`
  flex: 1;
  margin: auto auto auto var(--spacing-6);
  border-radius: var(--border-radius-medium);

  @media (max-width: 1200px) {
    margin: var(--spacing-6) 0 0 0;
  }

  iframe {
    border-radius: var(--border-radius-medium);
  }
`;

const StyledBodyText = styled(BodyText).attrs({ color: '--text-color' })``;

const StyledHeading = styled(Heading)`
  margin-bottom: var(--spacing-3);
`;

const StyledList = styled.ul`
  margin: var(--spacing-3) 0 var(--spacing-3) var(--spacing-4);
`;

export default function Plans(): JSX.Element | null {
  const { hasInitialized, hasLabsFlag } = usePSLabs();
  const { push } = useHistory();

  if (!hasInitialized) {
    return null;
  }

  if (!hasLabsFlag(SELF_MIGRATE_TO_USAGE_BILLING)) {
    push('/billing');
    return null;
  }

  return (
    <>
      <PageHeader
        pageTitle="Switch to usage billing"
        breadCrumb={{
          to: '/billing',
          text: 'billing',
        }}
      />
      <StyledCard data-theme="dark">
        <ContentWrapper>
          <StyledHeading size="small">Introducing usage billing</StyledHeading>
          <StyledBodyText>
            A simpler, more transparent way to manage your SMS marketing spend
            <br />
            <br />
            <strong>Here&apos;s why you should switch today:</strong>
          </StyledBodyText>
          <StyledList>
            <li>
              You&apos;ll only be charged for what you send. No more credits; no
              more rollover management.
            </li>
            <li>No need to remember to upgrade or downgrade.</li>
            <li>Pinpoint activities that drive your spend.</li>
            <li>
              Review your usage by exact message, day, country, message type
              (SMS/MMS), and more.
            </li>
            <li>Track, control, and predict your spend for each month.</li>
          </StyledList>
          <StyledBodyText>
            Select a plan get started or{' '}
            <LinkText
              onClick={(event) =>
                logLinkClickEvent(event, {
                  product_area: PRODUCT_AREAS.BILLING,
                })
              }
              href="https://help.postscript.io/hc/en-us/articles/4456127936155-Understanding-Your-Postscript-Bill-and-Charges"
              target="_blank"
              rel="noopener noreferrer"
            >
              learn more
            </LinkText>
            .
          </StyledBodyText>
        </ContentWrapper>
        <MediaWrapper>
          <ExplainerVideo />
        </MediaWrapper>
      </StyledCard>
      <StyledHeading forwardedAs="h2" size="medium">
        Usage billing plans:
      </StyledHeading>
      <MarketingPlansList />
    </>
  );
}
