import * as Sentry from '@sentry/browser';
import { hasIn } from 'lodash';
import { SENTRY_RELEASE, SENTRY_SAMPLE_RATE } from 'utils/envVars';

const silentErrors = [
  `Unexpected token '<'`,
  `Can't find variable: _hsq`,
  '_hsq is not defined',
  'Please load Stripe',
  'pendo is not defined',
  `Can't find variable: pendo`,
  'amplitude is not defined',
  `Can't find variable: amplitude`,
  'window.amplitude.add is not a function',
  'window.zE is not a function',
];

export const initSentry = (environment) => {
  const replay = new Sentry.Replay({
    // Be careful adding to this list, we should only allow urls that do not contain PII
    networkDetailAllowUrls: [
      '/automations',
      '/keywords',
      '/shopify_triggers',
      '/tags',
      '/v2/shops/labs/features',
      /\/v2\/billing\/admin\/.*/,
    ],
  });

  Sentry.init({
    dsn: 'https://63a92b5c13944fcab35b6f19347b4c97@o142330.ingest.sentry.io/1251963',
    release: SENTRY_RELEASE,
    integrations: [new Sentry.BrowserTracing(), replay],
    environment,
    allowUrls: [/https:\/\/(dev|app)\.postscript\.io/],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
    tracesSampleRate: parseFloat(SENTRY_SAMPLE_RATE),
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: environment === 'production' ? 1 : 0,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (!hasIn(error, 'message')) return event;

      // Check that error isn't an error we want to swallow
      if (
        !silentErrors.some((silentError) => error.message.includes(silentError))
      )
        return event;

      return null;
    },
  });

  return {
    replay,
  };
};
