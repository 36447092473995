import type { BadgeProps } from '@postscript/components/dist/esm/components/basic/Badge/Badge';
import { formatInvoiceStatus } from '../modules/invoices/helpers';
import { InvoiceStatuses, LedgerRecordTypes, MessageRateInput } from './types';

export const CHARACTER_COUNT_HELP_URL =
  'https://help.postscript.io/hc/en-us/articles/1260804629950';

export const MMS_ENABLED_COUNTRY_CODES = ['US', 'CA'];

export const DEFAULT_INITIAL_MESSAGE_RATES: MessageRateInput[] = [
  {
    countryCode: 'US',
    formattedSmsRate: '0.00',
    formattedMmsRate: '0.00',
  },
  {
    countryCode: 'CA',
    formattedSmsRate: '0.00',
    formattedMmsRate: '0.00',
  },
  {
    countryCode: '*',
    formattedSmsRate: '0.00',
    formattedMmsRate: 'N/A',
  },
];

export const TYPICAL_MESSAGE_RATE_RANGES = {
  US: {
    sms: {
      low: 0.3,
      high: 1.5,
    },
    mms: {
      low: 0.9,
      high: 4.5,
    },
  },
  CA: {
    sms: {
      low: 0.3,
      high: 1.5,
    },
    mms: {
      low: 0.9,
      high: 4.5,
    },
  },
  '*': {
    sms: {
      low: 3,
      high: 15,
    },
    mms: {
      low: 0,
      high: 0,
    },
  },
} as const;

export const HUBSPOT_FORM_IDS = {
  PROFESSIONAL: '89f5db50-e3e6-4b02-b10b-13558f637101',
  ENTERPRISE: 'd65eb76d-cfe0-47ca-b12c-f31636fdf517',
};

export const LEDGER_RECORD_TYPES = {
  SMS_SALES_REVENUE_SHARE: {
    value: 'SMS_SALES_REVENUE_SHARE',
    name: 'SMS Sales commission',
  },
  USAGE_CHARGE: {
    value: 'USAGE_CHARGE',
    name: 'Messaging fees',
  },
  SAAS_CHARGE: {
    value: 'SAAS_CHARGE',
    name: 'Platform fee',
  },
  CARRIER_FEES: {
    value: 'CARRIER_FEES',
    name: 'Carrier fees',
  },
  REFUND: {
    value: 'REFUND',
    name: 'Refund',
  },
  SHORT_CODE: {
    value: 'SHORT_CODE',
    name: 'Short code',
  },
  RECURRING: {
    value: 'RECURRING',
    name: 'Recurring fee',
  },
  CREDIT: {
    value: 'CREDIT',
    name: 'Credit',
  },
  USAGE_CREDIT: {
    value: 'USAGE_CREDIT',
    name: 'Usage credit',
  },
  MINIMUM_SPEND_COMMITMENT: {
    value: 'MINIMUM_SPEND_COMMITMENT',
    name: 'Minimum spend commitment',
  },
  OTHER: {
    value: 'OTHER',
    name: 'Other',
  },
  CREDIT_MIGRATION: {
    value: 'CREDIT_MIGRATION',
    name: 'Migration credit',
  },
  CREDIT_CORRECTION: {
    value: 'CREDIT_CORRECTION',
    name: 'Correction credit',
  },
  CREDIT_ONBOARDING: {
    value: 'CREDIT_ONBOARDING',
    name: 'Onboarding credit',
  },
  CREDIT_CX_PROVIDED: {
    value: 'CREDIT_CX_PROVIDED',
    name: 'CX credit',
  },
  SALES_TAX: {
    value: 'SALES_TAX',
    name: 'Sales taxes',
  },
  MARKETING_AI: {
    value: 'MARKETING_AI',
    name: 'Platform fee',
  },
  MARKETING_AI_USAGE: {
    value: 'MARKETING_AI_USAGE',
    name: 'Postscript AI variant messages',
  },
  MARKETING_AI_CONTROL_USAGE: {
    value: 'MARKETING_AI_CONTROL_USAGE',
    name: 'Postscript AI control messages',
  },
  CONTRACT_TERMINATION: {
    value: 'CONTRACT_TERMINATION',
    name: 'Contract termination fees',
  },
} as const;

export const PACKAGES = {
  Starter: {
    description: 'Brands just getting started with SMS',
    includedFeaturesPlanName: undefined,
    hasFreeTrialCTA: false,
    features: [
      'Campaigns and Automations',
      'Unlimited Segments',
      'All subscriber acquisition tools, with 2 default opt-in Keywords',
      'Integrations',
      'Help center',
      'Unique toll-free number',
      'Reporting',
    ],
  },
  Growth: {
    description: 'Brands building up their SMS program',
    hasFreeTrialCTA: false,
    includedFeaturesPlanName: 'Starter',
    features: ['Unlimited keywords', 'Live chat and email support'],
  },
  Professional: {
    description:
      'Established brands who are scaling up SMS as a top revenue channel',
    hasFreeTrialCTA: true,
    includedFeaturesPlanName: 'Growth',
    features: [
      'Onboarding Specialist to help you launch',
      'Dedicated Customer Success Manager for SMS strategy',
      'Priority live chat and email support',
      'Tier 1 engineering support',
      'API access for custom integrations',
      '"Powered by Postscript" removed from opt-in tools',
      'Custom branded short link subdomains',
    ],
  },
  Enterprise: {
    description:
      'Established brands with a long-term commitment to SMS success',
    hasFreeTrialCTA: false,
    includedFeaturesPlanName: 'Professional',
    features: [
      'Dedicated Short Code included',
      'Dedicated Enterprise Customer Success Manager for SMS strategy',
      'Data Warehouse Push',
      'Send personalized messages with custom product recommendations',
    ],
  },
};

export const MULTIPLIER_FRACTION_DIGITS = 10;

export const INVOICING_STRATEGIES = {
  IMMEDIATE: {
    value: 'IMMEDIATE',
    name: 'Immediate',
    description: 'on every ledger record (excludes carrier fees)',
  },
  MONTHLY: {
    value: 'MONTHLY',
    name: 'Monthly',
    description: 'end of the month',
  },
};

export const INVOICING_FREQUENCIES = {
  MONTHLY: 'MONTHLY',
  IMMEDIATE: 'IMMEDIATE',
};

export const PLAN_TYPES = {
  STANDARD: 'standard',
  FREE_TRIAL: 'free_trial',
};

export const LONG_DATE_FORMAT = 'MMM D, YYYY';
export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
export const MONTH_YEAR_DATE_FORMAT = 'MMMM YYYY';

export const PAYMENT_PROVIDERS = { SHOPIFY: 'SHOPIFY', STRIPE: 'STRIPE' };

export const RAC_APPROVED_SUCCESS_PATH = '/dashboard?rac_approved=true';

export const RAC_STATUSES = {
  PENDING: 'pending',
  ACTIVE: 'active',
};

export const PAYMENT_METHOD_BANNER_ID = 'payment-method-banner';

export const SHOP_PAST_DUE_BANNER_ID = 'shop-past-due-banner';

export const SHOP_FAILED_INVOICE_BANNER_ID = 'shop-failed-invoice-banner';

export const RAC_FLOW_BANNER_ID = 'rac-flow-banner';

export const RAC_APPROVED_BANNER_ID = 'rac-approved-banner';

export const LEGACY_BILLING_PAST_DUE_BANNER_ID =
  'legacy-billing-past-due-banner';

export const INVOICE_PAYMENT_WARNING_SHOPIFY =
  'Approve your pending Shopify app charge and try again.';

export const INVOICE_PAYMENT_WARNING_STRIPE = 'Please add a payment method.';

export const RECURRING_FEE_FREQUENCIES = {
  DAILY: {
    value: 'DAILY',
    name: 'Daily',
  },
  WEEKLY: {
    value: 'WEEKLY',
    name: 'Weekly',
  },
  MONTHLY: {
    value: 'MONTHLY',
    name: 'Monthly',
  },
  QUARTERLY: {
    value: 'QUARTERLY',
    name: 'Quarterly',
  },
  ANNUALLY: {
    value: 'ANNUALLY',
    name: 'Annually',
  },
};

export const RECURRING_FEE_TYPES = {
  GENERIC: {
    value: 'GENERIC',
    name: 'Generic',
  },
  SAAS_FEE: {
    value: 'SAAS_FEE',
    name: 'Platform fee',
  },
  CARRIER_FEES: {
    value: 'CARRIER_FEES',
    name: 'Carrier fees',
  },
  PS_PLUS: {
    value: 'PS_PLUS',
    name: 'PS Plus',
  },
  SHORT_CODE: {
    value: 'SHORT_CODE',
    name: 'Short code',
  },
  SMS_SALES_FIXED_FEE: {
    value: 'SMS_SALES_FIXED_FEE',
    name: 'SMS Sales fixed fee',
  },
  SMS_SALES_REVENUE_SHARE_NEW: {
    value: 'SMS_SALES_REVENUE_SHARE_NEW',
    name: 'SMS Sales new order commission',
  },
  SMS_SALES_REVENUE_SHARE_REPEAT: {
    value: 'SMS_SALES_REVENUE_SHARE_REPEAT',
    name: 'SMS Sales repeat order commission',
  },
  FONDUE_CREDIT: {
    value: 'FONDUE_CREDIT',
    name: 'Fondue bundle credit',
  },
  CREDIT: {
    value: 'CREDIT',
    name: 'Credit',
  },
  MARKETING_AI: {
    value: 'MARKETING_AI',
    name: 'Marketing AI fee',
  },
  MARKETING_AI_USAGE: {
    value: 'MARKETING_AI_USAGE',
    name: 'Postscript AI variant messages',
  },
  MARKETING_AI_CONTROL_USAGE: {
    value: 'MARKETING_AI_CONTROL_USAGE',
    name: 'Postscript AI control messages',
  },
};

export const DEFAULT_PREFERENCES = {
  hideInactiveRecurringFees: true,
};

export const DATEPICKER_DATE_FORMAT = 'YYYY-MM-DD';

export const INVOICE_STATUSES = {
  PROCESSING: 'PROCESSING',
  PENDING: 'PENDING',
  PAID: 'PAID',
  FAILED: 'FAILED',
  FORGIVEN: 'FORGIVEN',
  REFUNDED: 'REFUNDED',
  SETTLING_TAXES: 'SETTLING_TAXES',
  CHARGING: 'CHARGING',
} as const;

export const PAYABLE_INVOICE_STATUSES: Partial<InvoiceStatuses>[] = [
  INVOICE_STATUSES.PENDING,
  INVOICE_STATUSES.FAILED,
];

export const SETTLED_INVOICE_STATUSES: Partial<InvoiceStatuses>[] = [
  INVOICE_STATUSES.PAID,
  INVOICE_STATUSES.FORGIVEN,
];

export const FEATURE_KEYS = {
  powered_by_postscript_disabled: 'powered_by_postscript_disabled',
  keywords: 'keywords',
  api_access: 'api_access',
  use_subscriber_tz: 'use_subscriber_tz',
  popup_ab_testing: 'popup_ab_testing',
  text_to_buy: 'pspay',
  omni_trends: 'omni_trends',
};

export const FREE_USAGE_CREDIT_BANNER_ID = 'free-usage-credit-banner';

export const FREE_TRIAL_BANNER_ID = 'free-trial-banner';

export const FREE_USAGE_CREDIT_AND_FREE_TRIAL_BANNER_ID =
  'free-usage-credit-and-free-trial-banner';

export interface CarrierFeesByCountry {
  [countryCode: string]: {
    [msgType: string]: number;
  };
}

export const TYPICAL_CARRIER_FEES_BY_COUNTRY: CarrierFeesByCountry = {
  US: {
    sms: 0.289,
    mms: 0.758,
  },
  CA: {
    sms: 0.72,
    mms: 1.76,
  },
};

export const STARTER_PLAN_KEYWORD_LIMIT = 2;

export const LEDGER_RECORD_USAGE_TYPES: LedgerRecordTypes[] = [
  'USAGE_CHARGE',
  'CARRIER_FEES',
];

export const LEDGER_RECORD_CREDIT_TYPES: LedgerRecordTypes[] = [
  'CREDIT',
  'USAGE_CREDIT',
  'REFUND',
  'CREDIT_MIGRATION',
  'CREDIT_CORRECTION',
  'CREDIT_ONBOARDING',
  'CREDIT_CX_PROVIDED',
];

export const LOWER_PLANS = ['starter', 'growth'];

export const MINIMUM_SPEND_COMMITMENT_PERIODS = {
  MONTHLY: {
    name: 'Monthly',
    value: '1',
  },
  QUARTERLY: {
    name: 'Quarterly',
    value: '3',
  },
};

type InvoiceStatusBadgeVariantsType = {
  [key in typeof INVOICE_STATUSES[keyof typeof INVOICE_STATUSES]]: BadgeProps['variant'];
};

export const INVOICE_STATUS_BADGE_VARIANTS: InvoiceStatusBadgeVariantsType = {
  [INVOICE_STATUSES.PAID]: 'success',
  [INVOICE_STATUSES.FORGIVEN]: 'success',
  [INVOICE_STATUSES.REFUNDED]: 'success',
  [INVOICE_STATUSES.FAILED]: 'error',
  [INVOICE_STATUSES.PENDING]: 'info',
  [INVOICE_STATUSES.PROCESSING]: 'neutral',
  [INVOICE_STATUSES.SETTLING_TAXES]: 'neutral',
  [INVOICE_STATUSES.CHARGING]: 'neutral',
};

export const INVOICES_TABLE_FILTERS = [
  { label: formatInvoiceStatus('OPEN' as InvoiceStatuses), value: 'OPEN' },
  ...Object.values(INVOICE_STATUSES).map((status) => ({
    label: formatInvoiceStatus(status),
    value: status,
  })),
];

export const INVOICES_TABLE_SORT_OPTIONS = [
  {
    label: 'Service period (new → old)',
    value: {
      property: 'effectiveStartDate',
      direction: 'desc',
    },
  },
  {
    label: 'Service period (old → new)',
    value: {
      property: 'effectiveStartDate',
      direction: 'asc',
    },
  },
  {
    label: 'Last updated (new → old)',
    value: {
      property: 'updatedAt',
      direction: 'desc',
    },
  },
  {
    label: 'Last updated (old → new)',
    value: {
      property: 'updatedAt',
      direction: 'asc',
    },
  },
  {
    label: 'Messages (high → low)',
    value: {
      property: 'messages',
      direction: 'desc',
    },
  },
  {
    label: 'Messages (low → high)',
    value: {
      property: 'messages',
      direction: 'asc',
    },
  },
  {
    label: 'Amount (high → low)',
    value: {
      property: 'amount',
      direction: 'desc',
    },
  },
  {
    label: 'Amount (low → high)',
    value: {
      property: 'amount',
      direction: 'asc',
    },
  },
] as const;

export const TRANSACTION_ERRORS_MAP = {
  'card was declined': 'Card declined',
  "cannot confirm this PaymentIntent because it's missing a payment method":
    'Missing payment method',
  'card has insufficient funds': 'Payment method has insufficient funds',
  'card number is incorrect': 'Invalid card number',
  'card does not support this type of purchase':
    "Card doesn't support this type of merchant category purchase",
  'Invalid account': 'Invalid bank or credit card account',
  'account has insufficient funds': 'Payment method has insufficient funds',
  'security code is invalid': 'Invalid card security code',
  'card has expired': 'Expired card',
  'Try again in a little bit':
    'An error occurred while processing your card. Try again later.',
  'bank account has been closed': 'Closed bank account',
  'This PaymentIntent requires a mandate':
    "Couldn't process bank account payment",
  'The customer has notified their bank that this payment was unauthorized':
    'Bank account payment declined',
  'security code is incorrect': 'Invalid card security code',
  'has no active card': 'Inactive card',
  NoneType: 'No payment method on-file. Add a payment method and try again.',
  'cannot use this bank account': "Couldn't process bank account payment",
  'The account number specified does not have the correct number structure':
    'Invalid card number',
  'This value must be greater than or equal to 1':
    'No payment method on-file. Add a payment method and try again.',
  'No such PaymentMethod':
    'No payment method on-file. Add a payment method and try again.',
  'This object cannot be accessed right now':
    'An error occurred while processing your card. Try again later.',
  'Internal server error':
    'An error occurred while processing your card. Try again later.',
  'Failed to create usage charge':
    'An invoice error occurred. Contact support.',
  'Gateway timeout':
    'An error occurred while processing your card. Try again later.',
  'Total price exceeds balance remaining':
    'Payment method has insufficient funds',
  '{': 'An error occurred while processing your card. Try again later.',
  RecurringApplicationCharge:
    "Payment couldn't be processed because you need to complete your billing setup via Shopify",
};

export const TRANSACTION_ERROR_FALLBACK =
  'Payment failed. Please contact support.';
